import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ListChildComponentProps } from 'react-window';

import { makeStyles, Tooltip } from '@material-ui/core';

import clsx from 'clsx';

import { AppDispatch } from '../app/store';
import Exposure from '../components/Exposure';
import UserCell, { UserCellLoading } from '../components/UserCell';
import { showStudentDialog } from '../home/reducer';
import { colors } from '../utils/theme';
import { CovidStatus, Proximity } from '../utils/types';
import { StudentCovidData } from './types';

export const EnrolledStudentCellHeight = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'grid',
    padding: theme.spacing(1, 0),
    height: EnrolledStudentCellHeight,
    gridTemplateColumns: '2fr 1fr 1fr',
    gridTemplateAreas: '"user courses-enrolled-in status"',
    borderBottom: '1px solid rgba(186, 186, 186, 0.5)',
    textAlign: 'right',

    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  user: {
    gridArea: 'user',
    alignSelf: 'center',
    marginLeft: theme.spacing(2),
    textAlign: 'left',
  },
  cellHeader: {
    color: 'rgba(51, 51, 51, 0.5)',
    height: '1rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  cellValue: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: colors.grey1,
  },
  coursesEnrolledIn: {
    gridArea: 'courses-enrolled-in',
  },
  status: {
    gridArea: 'status',
  },
  loaderStrip: theme.mixins.loaderStrip,
  loaderAvatar: theme.mixins.loaderAvatar,
}));

const statusTooltipMap = {
  [CovidStatus.RECOVERED]: '',
  [CovidStatus.UNKNOWN]: 'Student has not attended any lecture with a student of concern, as per our records',
  [CovidStatus.POSITIVE]: 'Student has been tested positive for COVID-19',
};

const exposedTooltipMap: {
  [key in Proximity]: string;
} = {
  [Proximity.VERY_CLOSE]:
    'Student has attended at least one lecture in very close proximity of a student of concern since the date of first exposure',
  [Proximity.CLOSE]:
    'Student has attended at least one lecture in close proximity of a student of concern since the date of first exposure',
  [Proximity.MODERATE]:
    'Student has attended at least one lecture in moderate proximity of a student of concern since the date of first exposure',
  [Proximity.FAR]:
    'Student has attended at least one lecture in distant proximity of a student of concern since the date of first exposure',
  [Proximity.VERY_FAR]:
    'Student has attended at least one lecture in very distant proximity of a student of concern since the date of first exposure',
  [Proximity.UNKNOWN]:
    'Student has attended at least one lecture with a student of concern since the date of first exposure but their proximity is unknown',
  [Proximity.DEFAULT]: '',
};

const EnrolledStudentCell = ({
  index,
  isScrolling,
  style,
  data,
}: ListChildComponentProps<SparseArray<StudentCovidData>>) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const student = data[index];

  const handleClick = useCallback(() => {
    if (!student?.userId) return;
    dispatch(showStudentDialog({ studentId: student.userId }));
  }, [dispatch, student?.userId]);

  if (!student || isScrolling) {
    return (
      <div className={styles.root} style={style}>
        <UserCellLoading className={styles.user} />
        <div className={styles.coursesEnrolledIn}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
      </div>
    );
  }

  const { avatar, name, emailId, coursesEnrolledIn, status, proximity } = student;

  return (
    <div className={styles.root} style={style} onClick={handleClick}>
      <UserCell className={styles.user} avatarURL={avatar} name={name} email={emailId} />
      <Tooltip enterDelay={1000} placement="top-end" title="Number of courses attended by this student">
        <div className={styles.coursesEnrolledIn}>
          <div className={styles.cellHeader}>Courses enrolled in</div>
          <div className={styles.cellValue}>{coursesEnrolledIn}</div>
        </div>
      </Tooltip>
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title={status !== CovidStatus.EXPOSED ? statusTooltipMap[status] : exposedTooltipMap[proximity]}
      >
        <div className={styles.status}>
          <div className={styles.cellHeader}>Status</div>
          <Exposure className={styles.cellValue} covidStatus={status} proximity={proximity} />
        </div>
      </Tooltip>
    </div>
  );
};

export default EnrolledStudentCell;
