import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { selectCoursesSummary, selectStudentSummary } from '../home/selectors';
import NavPanel from './NavPanel';
import NavPanelItem from './NavPanelItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(4),
  },
}));

interface Props {
  className?: string;
}

const Nav = ({ className }: Props) => {
  const styles = useStyles();

  const studentSummary = useSelector(selectStudentSummary);
  const coursesSummary = useSelector(selectCoursesSummary);

  return (
    <nav className={clsx(className, styles.root, 'custom-scrollbar')}>
      <NavPanel variant="blue" title="Students">
        <NavPanelItem
          variant="blue"
          to="/students/enrolled"
          label="Enrolled students"
          rightLabel={studentSummary.numEnrolled}
        />
        <NavPanelItem
          variant="blue"
          to="/students/concern"
          label="Students of concern"
          rightLabel={studentSummary.numConcern}
        />
        <NavPanelItem
          variant="blue"
          to="/students/exposed"
          label="Exposed students"
          rightLabel={studentSummary.numExposed}
        />
      </NavPanel>
      <NavPanel variant="purple" title="Courses">
        <NavPanelItem
          variant="purple"
          label="In-person courses on record"
          rightLabel={coursesSummary.numRecord}
        />
        <NavPanelItem
          variant="purple"
          label="Attendance recorded in"
          rightLabel={`${coursesSummary.numLectures} lectures`}
        />
        <NavPanelItem
          isHeader
          variant="purple"
          label="Affected courses"
          rightLabel={coursesSummary.numAffected}
        />
        {coursesSummary.affected.map((course) => (
          <NavPanelItem
            key={course.courseId}
            to={`/courses/${course.courseId}`}
            variant="purple"
            label={course.code}
            rightLabel={`${course.numStudents} students`}
          />
        ))}
      </NavPanel>
    </nav>
  );
};

export default Nav;
