import { FormEventHandler, RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  makeStyles,
  Portal,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DatePicker } from '@material-ui/pickers';

import clsx from 'clsx';
import { format } from 'date-fns';

import { AppDispatch } from '../app/store';
import LoaderButton from '../components/LoaderButton';
import { colors } from '../utils/theme';
import { CovidStatus } from '../utils/types';
import { fetchStudentCovidTestRecord, updateCovidTestRecord } from './reducer';
import { selectStudentDialogState } from './selectors';
import { CovidTestRecordAction } from './types';

const DATE_FORMAT = 'yyyy-MM-dd';

const useStyles = makeStyles((theme) => ({
  root: {
    color: colors.grey3,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
    minHeight: 40,

    '&.enabled': {
      color: '#000',
    },
  },
  label: {
    flex: 1,
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  input: {
    flexShrink: 0,
    width: 140,
    textAlign: 'right',
  },
  retract: {
    height: 24,
    width: 96,
    backgroundColor: '#EB5757',
    color: '#fff',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
  },
  dateInput: {
    textAlign: 'right',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    color: colors.grey3,

    '.enabled &': {
      color: '#000',
      cursor: 'pointer',
    },
  },
  calenderIcon: {
    color: colors.grey3,

    '.enabled &': {
      color: '#000',
    },

    '&.error': {
      color: theme.palette.error.main,
    },
  },
  historyLabel: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  history: {
    margin: 0,
    paddingLeft: theme.spacing(3),
  },
  footer: {
    width: '100%',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  submit: {
    color: colors.orange,
  },
  loaderStrip: theme.mixins.loaderStrip,
}));

interface Props {
  studentId: string;
  actionContainerRef: RefObject<HTMLDivElement>;
}

const CovidTestForm = ({ studentId, actionContainerRef }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const { studentProfile, testRecord: covidTestRecord } = useSelector(selectStudentDialogState);

  const [isTestedPositive, setIsTestedPositive] = useState(false);

  const [testedPositiveOn, setTestedPositiveOn] = useState<Date | null>(null);
  const [testedPositiveOnError, setTestedPositiveOnError] = useState(false);

  const [traceStartDate, setTraceStartDate] = useState<Date | null>(null);
  const [traceStartDateError, setTraceStartDateError] = useState(false);

  const [isRecovered, setIsRecovered] = useState(false);

  const [recoveredOn, setRecoveredOn] = useState<Date | null>(null);
  const [recoveredOnError, setRecoveredOnError] = useState(false);

  const [notifyAdmins, setNotifyAdmins] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [isConfirmRetractAlertOpen, setIsConfirmRetractAlertOpen] = useState(false);

  const resetForm = () => {
    setIsTestedPositive(false);

    setTestedPositiveOn(null);
    setTestedPositiveOnError(false);

    setTraceStartDate(null);
    setTraceStartDateError(false);

    setIsRecovered(false);

    setRecoveredOn(null);
    setRecoveredOnError(false);

    setNotifyAdmins(false);
  };

  useEffect(() => {
    if (!covidTestRecord) {
      dispatch(fetchStudentCovidTestRecord(studentId));
    }
  }, [covidTestRecord, dispatch, studentId]);

  useEffect(() => {
    if (!covidTestRecord) return;

    if (studentProfile?.status === CovidStatus.POSITIVE) {
      setTestedPositiveOn(new Date(covidTestRecord.testDate * 1000));
      setTraceStartDate(new Date(covidTestRecord.traceStartDate * 1000));
    } else if (studentProfile?.status === CovidStatus.RECOVERED) {
      setRecoveredOn(new Date(covidTestRecord.recoveredOn * 1000));
    } else {
      resetForm();
    }
  }, [covidTestRecord, studentProfile?.status]);

  const toggleIsTestedPositive = () => {
    setIsTestedPositive((isChecked) => {
      if (isChecked) {
        setTestedPositiveOn(null);
        setTestedPositiveOnError(false);
        setTraceStartDate(null);
        setTraceStartDateError(false);
      }
      return !isChecked;
    });
  };

  const toggleIsRecovered = () => {
    setIsRecovered((isChecked) => {
      if (isChecked) {
        setRecoveredOn(null);
        setRecoveredOnError(false);
      }
      return !isChecked;
    });
  };

  const toggleNotifyAdmins = () => setNotifyAdmins((isChecked) => !isChecked);

  const showConfirmRetractAlert = () => setIsConfirmRetractAlertOpen(true);
  const hideConfirmRetractAlert = () => setIsConfirmRetractAlertOpen(false);

  const confirmRetract = async () => {
    hideConfirmRetractAlert();
    setIsSaving(true);
    await dispatch(
      updateCovidTestRecord({
        action: CovidTestRecordAction.RETRACT,
        userId: studentId,
      })
    );
    setIsSaving(false);
  };

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!studentProfile) return;

    let hasError = false;

    if (studentProfile.status !== CovidStatus.POSITIVE) {
      if (!testedPositiveOn) {
        setTestedPositiveOnError(true);
        hasError = true;
      }
      if (!traceStartDate) {
        setTraceStartDateError(true);
        hasError = true;
      }
    } else if (!recoveredOn) {
      setRecoveredOnError(true);
      hasError = true;
    }

    if (hasError) return;

    setIsSaving(true);

    if (isRecovered) {
      await dispatch(
        updateCovidTestRecord({
          action: CovidTestRecordAction.RECOVERED,
          userId: studentId,
          recoveredOn: format(recoveredOn!, DATE_FORMAT),
          notifyAdmins: notifyAdmins ? 1 : 0,
        })
      );
      resetForm();
    } else if (isTestedPositive) {
      await dispatch(
        updateCovidTestRecord({
          action: CovidTestRecordAction.POSITIVE,
          userId: studentId,
          testedOn: format(testedPositiveOn!, DATE_FORMAT),
          traceStartDate: format(traceStartDate!, DATE_FORMAT),
          notifyAdmins: notifyAdmins ? 1 : 0,
        })
      );
    }

    setIsSaving(false);
  };

  if (!covidTestRecord) {
    return (
      <div className={styles.root}>
        <div className={styles.row}>
          <div className={styles.label}>
            <div className={clsx(styles.loaderStrip, 'tall')} />
          </div>
          <div className={styles.input}>
            <div className={clsx(styles.loaderStrip, 'quarter', 'tall', 'right')} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <div className={clsx(styles.loaderStrip, 'tall')} />
          </div>
          <div className={styles.input}>
            <div className={clsx(styles.loaderStrip, 'tall', 'right')} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <div className={clsx(styles.loaderStrip, 'tall')} />
          </div>
          <div className={styles.input}>
            <div className={clsx(styles.loaderStrip, 'tall', 'right')} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <div className={clsx(styles.loaderStrip, 'tall')} />
          </div>
          <div className={styles.input}>
            <div className={clsx(styles.loaderStrip, 'quarter', 'tall', 'right')} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            <div className={clsx(styles.loaderStrip, 'tall')} />
          </div>
          <div className={styles.input}>
            <div className={clsx(styles.loaderStrip, 'tall', 'right')} />
          </div>
        </div>
        <div className={styles.historyLabel}>
          <div className={clsx(styles.loaderStrip, 'tall', 'quarter')} />
        </div>
        <div className={styles.history}>
          <div className={clsx(styles.loaderStrip, 'tall')} />
          <div className={clsx(styles.loaderStrip, 'tall')} />
        </div>
      </div>
    );
  }

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      <div className={clsx(styles.row, 'enabled')}>
        <div className={styles.label}>This student tested positive</div>
        <div className={styles.input}>
          {studentProfile?.status === CovidStatus.POSITIVE ? (
            <ButtonBase
              disabled={isSaving}
              className={styles.retract}
              type="button"
              onClick={showConfirmRetractAlert}
            >
              Retract
            </ButtonBase>
          ) : (
            <Checkbox color="primary" checked={isTestedPositive} onChange={toggleIsTestedPositive} />
          )}
        </div>
      </div>
      <div className={clsx(styles.row, { enabled: isTestedPositive })}>
        <div className={styles.label}>
          <span>Tested positive on</span>
          {testedPositiveOnError && <span className={styles.error}>*Required</span>}
        </div>
        <div className={styles.input}>
          <DatePicker
            autoOk
            margin="none"
            error={testedPositiveOnError}
            disabled={!isTestedPositive}
            format="do MMM, yyyy"
            value={testedPositiveOn}
            onChange={setTestedPositiveOn}
            InputProps={{
              startAdornment: (
                <DateRangeIcon
                  className={clsx(styles.calenderIcon, {
                    error: testedPositiveOnError,
                  })}
                />
              ),
              inputProps: { className: styles.dateInput },
            }}
          />
        </div>
      </div>
      <div className={clsx(styles.row, { enabled: isTestedPositive })}>
        <div className={styles.label}>
          <span>Proximity tracing to be started from</span>
          {traceStartDateError && <span className={styles.error}>*Required</span>}
        </div>
        <div className={styles.input}>
          <DatePicker
            autoOk
            margin="none"
            error={traceStartDateError}
            disabled={!isTestedPositive}
            format="do MMM, yyyy"
            value={traceStartDate}
            onChange={setTraceStartDate}
            InputProps={{
              startAdornment: (
                <DateRangeIcon
                  className={clsx(styles.calenderIcon, {
                    error: traceStartDateError,
                  })}
                />
              ),
              inputProps: { className: styles.dateInput },
            }}
          />
        </div>
      </div>
      <div
        className={clsx(styles.row, {
          enabled: studentProfile?.status === CovidStatus.POSITIVE,
        })}
      >
        <div className={styles.label}>This student has recovered</div>
        <div className={styles.input}>
          <Checkbox
            color="primary"
            checked={isRecovered}
            onChange={toggleIsRecovered}
            disabled={studentProfile?.status !== CovidStatus.POSITIVE}
          />
        </div>
      </div>
      <div className={clsx(styles.row, { enabled: isRecovered })}>
        <div className={styles.label}>
          <span>Marked recovered on</span>
          {recoveredOnError && <span className={styles.error}>*Required</span>}
        </div>
        <div className={styles.input}>
          <DatePicker
            autoOk
            margin="none"
            error={recoveredOnError}
            disabled={!isRecovered}
            format="do MMM, yyyy"
            value={recoveredOn}
            onChange={setRecoveredOn}
            InputProps={{
              startAdornment: (
                <DateRangeIcon
                  className={clsx(styles.calenderIcon, {
                    error: recoveredOnError,
                  })}
                />
              ),
              inputProps: { className: styles.dateInput },
            }}
          />
        </div>
      </div>
      <div className={styles.historyLabel}>History</div>
      {covidTestRecord.history.length > 0 ? (
        <ul className={styles.history}>
          {covidTestRecord.history.map((line, index) => (
            <li key={index}>{line}</li>
          ))}
        </ul>
      ) : (
        <div>--</div>
      )}
      <Portal container={actionContainerRef.current}>
        <div className={styles.footer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyAdmins}
                onChange={toggleNotifyAdmins}
                name="notifyAdmins"
                color="primary"
              />
            }
            label="Notify Course Admins"
          />
          <LoaderButton
            color="secondary"
            isLoading={isSaving}
            disabled={!isTestedPositive && !isRecovered}
            onClick={handleSubmit}
          >
            Save
          </LoaderButton>
        </div>
      </Portal>
      <Dialog maxWidth="xs" open={isConfirmRetractAlertOpen}>
        <DialogContent>
          <DialogContentText color="textPrimary">
            Retracting means that this student was wrongly marked as a student of concern and is not the same
            as recovery. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideConfirmRetractAlert} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRetract} color="secondary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CovidTestForm;
