import authReducer from '../auth/reducer';
import coursesReducer from '../courses/reducer';
import homeReducer from '../home/reducer';
import studentsReducer from '../students/reducer';

const rootReducer = {
  auth: authReducer,
  home: homeReducer,
  courses: coursesReducer,
  students: studentsReducer,
};

export default rootReducer;
