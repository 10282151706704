export enum AuthScreen {
  /**
   * User enters their email address to login
   */
  EMAIL = 'email',
  /**
   * User enters verifcation code sent to their email address
   * to verify the email address
   */
  VERIFY_EMAIL = 'verify-email',
  /**
   * User enters their password to login, appears after email screen
   */
  GET_PASSWORD = 'login',
  /**
   * User creates a password for login, appears after verify email screen
   */
  SET_PASSWORD = 'set-password',
  /**
   * User enters verifcation code sent to their email address,
   * appears after clicking on forgot-password button
   */
  FORGOT_PASSWORD = 'verify-pw-reset',
  /**
   * User creates a new password after resetting it, appears after forgot password screen
   */
  RESET_PASSWORD = 'reset-password',
}
