import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../app/store';
import { selectStudentSummary } from '../home/selectors';
import { debounce } from '../utils/timers';
import { fetchStudentCovidData, resetList } from './reducer';
import { selectStudents } from './selectors';
import { filterByToNumMap, StudentCovidDataFilter } from './types';

export const useStudentList = (filterBy: StudentCovidDataFilter) => {
  const isFirstLoad = useRef(true);
  const dispatch = useDispatch<AppDispatch>();

  const studentSummary = useSelector(selectStudentSummary);
  const studentData = useSelector(selectStudents(filterBy));

  const [searchTerm, setSearchTerm] = useState('');

  const refreshListData = useCallback(
    async (searchTerm: string, limit: number, total: number) => {
      dispatch(
        resetList({
          [filterBy]: { total },
        })
      );
      if (searchTerm.length === 0 || searchTerm.length > 2) {
        /**
         * if searchTerm has 0 or more than 2 characters
         * then refresh list data by sending server request
         */
        await dispatch(
          fetchStudentCovidData({
            filterBy,
            limit,
            searchTerm,
            skip: 0,
          })
        );
      }
    },
    [dispatch, filterBy]
  );

  const refreshListDataDebounced = useMemo(() => debounce(refreshListData, 500), [refreshListData]);

  const loadMoreItems = useMemo(() => {
    return debounce((startIndex: number, endIndex: number) => {
      if (isFirstLoad.current) return;

      if (searchTerm.length > 0 && searchTerm.length < 3) {
        /**
         * don't fetch data when search text length is between 0 and 3
         * but show list as if data is being fetched
         */
        return;
      }

      dispatch(
        fetchStudentCovidData({
          filterBy,
          limit: endIndex - startIndex + 1,
          searchTerm: searchTerm,
          skip: startIndex,
        })
      );
    }, 500);
  }, [dispatch, filterBy, searchTerm]);

  useEffect(() => {
    isFirstLoad.current = true;
  }, [filterBy]);

  useEffect(() => {
    const totalStudents = studentSummary[filterByToNumMap[filterBy]];

    if (!totalStudents) return;

    if (isFirstLoad.current) {
      refreshListData('', 100, totalStudents).then(() => {
        isFirstLoad.current = false;
      });
    } else {
      const searchLength = searchTerm.length;
      const limit = searchLength > 0 ? 50 : 100;
      const total = searchLength > 0 ? 50 : totalStudents;
      refreshListDataDebounced(searchTerm, limit, total);
    }
  }, [filterBy, refreshListData, refreshListDataDebounced, searchTerm, studentSummary]);

  return {
    loadMoreItems,
    refreshListData,
    refreshListDataDebounced,
    searchTerm,
    setSearchTerm,
    studentData,
    studentSummary,
  };
};
