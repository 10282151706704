import { makeStyles, Typography } from '@material-ui/core';

import clsx from 'clsx';

import { ReactComponent as AcadlyLogo } from '../assets/acadly-logo.svg';
import { colors } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.4,
  },
  poweredBy: {
    color: colors.grey1,
    lineHeight: 1.25,
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
  },
  logo: {
    height: 30,
    width: 'auto',
  },
}));

interface Props {
  className?: string;
}

const PoweredBy = ({ className }: Props) => {
  const styles = useStyles();
  return (
    <footer className={clsx(className, styles.root)}>
      <Typography className={styles.poweredBy} component="div">
        powered by
      </Typography>
      <AcadlyLogo className={styles.logo} />
    </footer>
  );
};

export default PoweredBy;
