import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import { AppDispatch } from '../app/store';
import { selectIsAuthenticated, selectUniversity } from '../auth/selectors';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import AffectedCourses from '../courses/AffectedCourses';
import ConcernedStudents from '../students/ConcernedStudents';
import EnrolledStudents from '../students/EnrolledStudents';
import ExposedStudents from '../students/ExposedStudents';
import { fetchCovidSummary } from './reducer';
import { selectIsHomeLoading } from './selectors';
import StudentProfileDialog from './StudentProfileDialog';

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();

  const university = useSelector(selectUniversity);
  const isLoading = useSelector(selectIsHomeLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (university) {
      dispatch(fetchCovidSummary());
    }
  }, [dispatch, university]);

  if (!isAuthenticated) {
    return <Redirect to="/auth/email" />;
  }

  return (
    <Layout>
      <Switch>
        {isLoading && <Loader />}
        <Route path="/students/enrolled">
          <EnrolledStudents />
        </Route>
        <Route path="/students/concern">
          <ConcernedStudents />
        </Route>
        <Route path="/students/exposed">
          <ExposedStudents />
        </Route>
        <Route path="/courses/:courseId">
          <AffectedCourses />
        </Route>
        <Route path="*">
          <Redirect to="/students/enrolled" />
        </Route>
      </Switch>
      <StudentProfileDialog />
    </Layout>
  );
};

export default Home;
