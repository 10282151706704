import MockAdapter from 'axios-mock-adapter';

import registerAuthMocks from '../auth/mocks';
import registerCoursesMocks from '../courses/mocks';
import registerHomeMocks from '../home/mocks';
import registerStudentsMocks from '../students/mocks';
import request from './request';

export const registerMocks = () => {
  const mockAdapter = new MockAdapter(request, {
    delayResponse: 2000,
  });

  registerAuthMocks(mockAdapter);
  registerHomeMocks(mockAdapter);
  registerStudentsMocks(mockAdapter);
  registerCoursesMocks(mockAdapter);

  return mockAdapter;
};
