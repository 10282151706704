export enum CovidStatus {
  UNKNOWN = '',
  POSITIVE = 'positive',
  EXPOSED = 'exposed',
  RECOVERED = 'recovered',
}

export enum Proximity {
  VERY_CLOSE = 'very-close',
  CLOSE = 'close',
  MODERATE = 'moderate',
  FAR = 'far',
  VERY_FAR = 'very-far',
  UNKNOWN = 'unknown',
  DEFAULT = '',
}

export interface PaginationData {
  limit: number;
  skip: number;
  searchTerm: string;
}

export interface AcadlyUser {
  userId: string;
  name: string;
  emailId: string;
  avatar: string;
}
