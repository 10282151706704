import { ReactNode, useEffect, useRef, useState } from 'react';
import { FixedSizeList as List, ListProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { debounce } from '../utils/timers';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  header: {
    flexShrink: 0,
    borderBottom: '3px solid #EEEEEE',
  },
  list: {
    flex: 1,
    overflow: 'auto',
  },
});

interface Props<T> {
  children: ListProps<T>['children'];
  classes?: {
    root?: string;
    header?: string;
    list?: string;
  };
  className?: string;
  data: ListProps<T>['itemData'];
  /** Fixed during scroll */
  header?: ReactNode;
  /** callback to check if a row item is loaded or not */
  isItemLoaded: (index: number) => boolean;
  itemKey?: ListProps<T>['itemKey'];
  itemCount: number;
  loadMoreItems: (startIndex: number, endIndex: number) => void | Promise<void>;
  /**
   * Minimum number of rows to be loaded at a time; defaults to 100.
   * This property can be used to batch requests to reduce HTTP requests.
   */
  minimumBatchSize?: number;
  rowHeight: number;
}

const VirtualList = <T,>({
  children,
  classes,
  className,
  data,
  header,
  isItemLoaded,
  itemKey,
  itemCount,
  loadMoreItems,
  minimumBatchSize = 100,
  rowHeight,
}: Props<T>) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const calculateParentHeight = debounce(() => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    }, 100);

    calculateParentHeight();
    window.addEventListener('resize', calculateParentHeight);

    return () => {
      window.removeEventListener('resize', calculateParentHeight);
    };
  }, []);

  return (
    <div className={clsx(styles.root, className, classes?.root)}>
      {header && <div className={clsx(styles.header, classes?.header)}>{header}</div>}
      <div ref={ref} className={clsx(styles.list, classes?.list)}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          minimumBatchSize={minimumBatchSize}
        >
          {({ onItemsRendered, ref }) => (
            <List
              className={clsx(styles.list, classes?.list)}
              height={height}
              width="100%"
              itemCount={itemCount}
              itemData={data}
              itemSize={rowHeight}
              itemKey={itemKey}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {children}
            </List>
          )}
        </InfiniteLoader>
      </div>
    </div>
  );
};

export default VirtualList;
