import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

import clsx from 'clsx';

import AcadlyLogo from '../assets/acadly-logo.svg';
import { logout } from '../auth/reducer';
import { selectUniversity } from '../auth/selectors';
import { colors } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateAreas: '"logo title logout"',
    gridTemplateColumns: 'min-content 1fr min-content',
    padding: theme.spacing(0, 4),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    gridArea: 'logo',
    width: 'auto',
    maxHeight: 60,
    marginRight: theme.spacing(2),
    background: 'transparent',
  },
  title: {
    gridArea: 'title',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: colors.grey1,
    margin: 0,
  },
  logout: {
    gridArea: 'logout',
    display: 'flex',
    alignItems: 'center',
    color: colors.red,
    fontSize: '1.25rem',
    cursor: 'pointer',
  },
  logoutIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  className?: string;
}

const Header = ({ className }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const university = useSelector(selectUniversity);

  const handleLogout = () => dispatch(logout());

  return (
    <header className={clsx(className, styles.root)}>
      <img className={styles.logo} src={university?.logo || AcadlyLogo} alt="logo" />
      <h1 className={styles.title}>Contact Trace</h1>
      <div role="button" className={styles.logout} onClick={handleLogout}>
        <ExitToApp className={styles.logoutIcon} />
        Logout
      </div>
    </header>
  );
};

export default Header;
