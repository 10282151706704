import request from '../utils/request';
import { PaginationData } from '../utils/types';
import { AffectedCourse, StudentCovidDataForCourse } from './types';

export interface GetStudentsForCourseParams extends PaginationData {
  courseId: string;
}

export interface GetStudentsForCourseResponse {
  message: 'success';
  course: AffectedCourse;
  students: StudentCovidDataForCourse[];
}

export async function getStudentsForCourse({
  courseId,
  limit,
  skip,
  searchTerm,
}: GetStudentsForCourseParams) {
  const response = await request.get<GetStudentsForCourseResponse>(`/students/course/${courseId}`, {
    params: { limit, skip, searchTerm },
  });
  return response.data;
}
