import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import history from '../utils/history';
import { AppContext, clearSession, getSession, saveSession, Session } from '../utils/session';
import {
  forgotPassword as forgotPasswordApi,
  getLTIAccessData,
  getUniversityData,
  logout as logoutApi,
} from './api';
import { AuthScreen } from './types';

interface University {
  cluster: string;
  id: string;
  logo: string;
  name: string;
}

interface AuthState {
  isAuthenticated: boolean;
  screen: AuthScreen;
  university: University | null;
  session: Session | null;
}

const AUTH = 'auth';
const session = getSession();

const initialState: AuthState = {
  session,
  university: null,
  screen: AuthScreen.EMAIL,
  isAuthenticated: Boolean(session?.postLoginAuth),
};

type SetUniversityDataAction = PayloadAction<University>;

const authSlice = createSlice({
  name: AUTH,

  initialState,

  reducers: {
    setScreen(state, action: PayloadAction<AuthScreen>) {
      state.screen = action.payload;
    },
    setUniversityData(state, action: SetUniversityDataAction) {
      state.university = action.payload;
    },
    setSession(state, action: PayloadAction<Session | null>) {
      state.session = action.payload;
      state.isAuthenticated = Boolean(action.payload?.postLoginAuth);

      if (action.payload) {
        saveSession(action.payload);
      } else {
        clearSession();
        state.screen = AuthScreen.EMAIL;
      }
    },
  },
});

export const { setScreen, setUniversityData, setSession } = authSlice.actions;

export const fetchUniversityData = createAsyncThunk(
  `${AUTH}/fetchUniversityData`,
  async (_, { dispatch }) => {
    const hostNamePrefix = window.location.hostname.split('.')[0];
    const universitySlug = process.env.REACT_APP_USE_MOCKS ? 'acetone' : hostNamePrefix;

    const { message, errorMessage, clusterUrl, logoUrl, universityId, universityName } =
      await getUniversityData(universitySlug);

    if (message === 'error') {
      history.push('/error', errorMessage);
      return;
    }

    dispatch(
      setUniversityData({
        cluster: clusterUrl,
        id: universityId,
        logo: logoUrl,
        name: universityName,
      })
    );
  }
);

export const fetchLTIAccessData = createAsyncThunk(
  `${AUTH}/fetchLTIAccessData`,
  async (accessCode: string, { dispatch }) => {
    const { message, errorMessage, name, emailId, preLoginAuth, next } = await getLTIAccessData(accessCode);

    if (message === 'error') {
      history.push('/error', errorMessage);
      return;
    }

    switch (next) {
      case AuthScreen.GET_PASSWORD:
      case AuthScreen.VERIFY_EMAIL:
        dispatch(
          setSession({
            name,
            emailId,
            preLoginAuth,
            context: AppContext.COURSE,
          })
        );
        dispatch(setScreen(next));
        break;
      case AuthScreen.EMAIL:
        dispatch(setScreen(AuthScreen.EMAIL));
        break;
      default:
        break;
    }
  }
);

export const forgotPassword = createAsyncThunk(`${AUTH}/forgotPassword`, async (_, { dispatch }) => {
  dispatch(setScreen(AuthScreen.FORGOT_PASSWORD));

  const { errorMessage } = await forgotPasswordApi();

  if (errorMessage) {
    alert(errorMessage);
  }
});

export const logout = createAsyncThunk(`${AUTH}/logout`, async (_, { dispatch }) => {
  logoutApi();
  dispatch(setSession(null));
});

const authReducer = authSlice.reducer;

export default authReducer;
