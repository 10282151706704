import MockAdapter from 'axios-mock-adapter/types';

import { CovidStatus, Proximity } from '../utils/types';
import {
  GetCovidSummaryResponse,
  GetExposedStudentsResponse,
  GetStudentCoursesResponse,
  GetStudentCovidTestRecordResponse,
} from './api';

function getStudentProfile(status = CovidStatus.EXPOSED) {
  return {
    userId: 'abcd',
    name: 'Alan Cooper',
    emailId: 'alan.cooper@uic.edu',
    avatar: '',
    coursesEnrolledIn: 2,
    proximity: Proximity.CLOSE,
    status,
  };
}

export default function registerHomeMocks(mockAdapter: MockAdapter) {
  mockAdapter.onGet('/summary').reply<GetCovidSummaryResponse>(200, {
    message: 'message',
    students: {
      numEnrolled: 1730,
      numExposed: 432,
      numConcern: 20,
    },
    courses: {
      numRecord: 32,
      numLectures: 162,
      numAffected: 5,
      affected: [
        {
          courseId: 'cd381270be5046958c5454b6c7769a3c',
          code: 'MATH-101',
          numStudents: 118,
        },
        {
          courseId: 'b0d27f69809648b58252c2d123f9e82e',
          code: 'PHY_203',
          numStudents: 72,
        },
        {
          courseId: 'd46ccaa1ed6a46e7b0bdde9823063bfa',
          code: 'KIN7734',
          numStudents: 43,
        },
        {
          courseId: '635e1439a3864b3abe27887392579e69',
          code: 'PSY391',
          numStudents: 62,
        },
        {
          courseId: '53822ff9b03e40a286ba5c4963e5228a',
          code: 'ART401',
          numStudents: 39,
        },
      ],
    },
  });

  mockAdapter.onGet('/students/courses').reply<GetStudentCoursesResponse>(200, {
    message: 'success',
    student: getStudentProfile(),
    courses: [
      {
        courseId: '1',
        admin: {
          userId: 'admin1',
          name: 'Jessica Lambert',
          emailId: 'jwlambert@uic.edu',
          avatar: '',
        },
        code: 'MATH-101',
        lecturesAttended: 10,
        numConcern: 3,
        title: 'Introduction to math - This is a long course title which should be ellipsized',
      },
      {
        courseId: '2',
        admin: {
          userId: 'admin2',
          name: 'Jessica Lambert',
          emailId: 'jwlambert@uic.edu',
          avatar: '',
        },
        code: 'MATH-102',
        lecturesAttended: 8,
        numConcern: 0,
        title: 'Another Introduction to math - This is a long course title which should be ellipsized',
      },
      {
        courseId: '3',
        admin: {
          userId: 'admin3',
          name: 'Jessica Lambert',
          emailId: 'jwlambert@uic.edu',
          avatar: '',
        },
        code: 'MATH-103',
        lecturesAttended: 18,
        numConcern: 3,
        title: 'Another Introduction to math - This is a long course title which should be ellipsized',
      },
      {
        courseId: '4',
        admin: {
          userId: 'admin4',
          name: 'Jessica Lambert',
          emailId: 'jwlambert@uic.edu',
          avatar: '',
        },
        code: 'MATH-104',
        lecturesAttended: 12,
        numConcern: 5,
        title: 'Another Introduction to math - This is a long course title which should be ellipsized',
      },
    ],
  });

  mockAdapter.onGet('/students/exposure').reply<GetExposedStudentsResponse>(200, {
    message: 'success',
    student: getStudentProfile(),
    exposure: [
      {
        userId: '1',
        name: 'James Gouse',
        emailId: 'jamesg@uic.edu',
        avatar: '',
        course: 'MATH-101',
        testedOn: 1597948200,
        instances: [
          {
            classTime: 1597775400,
            proximity: Proximity.VERY_CLOSE,
          },
          {
            classTime: 1597861800,
            proximity: Proximity.CLOSE,
          },
          {
            classTime: 1598034600,
            proximity: Proximity.UNKNOWN,
          },
          {
            classTime: 1598121000,
            proximity: Proximity.FAR,
          },
          {
            classTime: 1598207400,
            proximity: Proximity.VERY_FAR,
          },
          {
            classTime: 1598293800,
            proximity: Proximity.MODERATE,
          },
        ],
      },
      {
        userId: '2',
        name: 'Alexis Sears',
        emailId: 'asears1@uic.edu',
        avatar: '',
        course: 'PSY101',
        testedOn: 1597948200,
        instances: [
          {
            classTime: 1597775400,
            proximity: Proximity.VERY_CLOSE,
          },
          {
            classTime: 1597861800,
            proximity: Proximity.MODERATE,
          },
          {
            classTime: 1598034600,
            proximity: Proximity.UNKNOWN,
          },
        ],
      },
      {
        userId: '3',
        name: 'Alan Cooper',
        emailId: 'alan.cooper@uic.edu',
        avatar: '',
        course: 'MATH-102',
        testedOn: 1597948200,
        instances: [
          {
            classTime: 1597775400,
            proximity: Proximity.VERY_CLOSE,
          },
          {
            classTime: 1597861800,
            proximity: Proximity.CLOSE,
          },
          {
            classTime: 1598034600,
            proximity: Proximity.MODERATE,
          },
          {
            classTime: 1598121000,
            proximity: Proximity.FAR,
          },
          {
            classTime: 1598207400,
            proximity: Proximity.VERY_FAR,
          },
          {
            classTime: 1598293800,
            proximity: Proximity.UNKNOWN,
          },
        ],
      },
    ],
  });

  mockAdapter.onGet('/students/test_record').reply<GetStudentCovidTestRecordResponse>(200, {
    message: 'success',
    student: getStudentProfile(CovidStatus.EXPOSED),
    testRecord: {
      testDate: 1629829800,
      traceStartDate: 1629484200,
      recoveredOn: 0,
      history: [],
      // history: ['25th August, 2021, 12:43 PM - Henry Knobworth marked the student as positive'],
    },
  });

  mockAdapter.onPut('/students/update_status').reply((config) => {
    const data = JSON.parse(config.data);

    let response: GetStudentCovidTestRecordResponse = {
      message: 'success',
      student: getStudentProfile(CovidStatus.POSITIVE),
      testRecord: {
        testDate: 1629829800,
        traceStartDate: 1629484200,
        recoveredOn: 0,
        history: ['25th August, 2021, 12:43 PM - Henry Knobworth marked the student as positive'],
      },
    };

    switch (data.action) {
      case 'retract':
        response = {
          message: 'success',
          student: getStudentProfile(CovidStatus.UNKNOWN),
          testRecord: {
            testDate: 1629829800,
            traceStartDate: 1629484200,
            recoveredOn: 0,
            history: [
              '26th August, 2021, 02:43 PM - Henry Knobworth retracted the record',
              '25th August, 2021, 12:43 PM - Henry Knobworth marked the student as positive',
            ],
          },
        };
        break;
      case 'recovered':
        response = {
          message: 'success',
          student: getStudentProfile(CovidStatus.RECOVERED),
          testRecord: {
            testDate: 0,
            traceStartDate: 0,
            recoveredOn: 1631039400,
            history: ['25th August, 2021, 12:43 PM - Henry Knobworth marked the student as positive'],
          },
        };
        break;
    }

    return [200, response];
  });
}
