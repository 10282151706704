import { FC } from 'react';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { colors } from '../utils/theme';
import NavPanelItem from './NavPanelItem';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${colors.blue1}`,
    borderRadius: 8,
    marginBottom: theme.spacing(2),

    '&.purple': {
      border: `2px solid ${colors.purple1}`,
    },
  },
}));

interface Props {
  className?: string;
  title: string;
  variant: 'blue' | 'purple';
}

const NavPanel: FC<Props> = ({ children, className, title, variant }) => {
  const styles = useStyles();

  return (
    <section className={clsx(className, styles.root, variant)}>
      <NavPanelItem isHeader variant={variant} label={title} />
      {children}
    </section>
  );
};

export default NavPanel;
