import MockAdapter from 'axios-mock-adapter/types';

import { AppContext } from '../utils/session';
import {
  EnterEmailResponse,
  ForgotPasswordResponse,
  GetLTIAccessDataResponse,
  GetUniversityDataResponse,
  LoginResponse,
  LogoutResponse,
  ResendVerificationCodeResponse,
  ResetPasswordResponse,
  SetPasswordResponse,
  VerifyEmailResponse,
} from './api';
import { AuthScreen } from './types';

export default function registerAuthMocks(mockAdapter: MockAdapter) {
  mockAdapter
    .onGet(`${process.env.REACT_APP_GATEWAY_URL}/get_ct_cluster`)
    .reply<GetUniversityDataResponse>(200, {
      message: 'success',
      clusterUrl: `${process.env.REACT_APP_GATEWAY_URL}/api`,
      errorMessage: '',
      logoUrl: 'https://www.acadly.com/images/header-logo.png',
      universityId: 'universityId',
      universityName: 'University of Acetone',
    });

  mockAdapter.onPost(`/lti_access`).reply<GetLTIAccessDataResponse>(200, {
    message: 'success',
    errorMessage: '',
    name: 'Anna White',
    emailId: 'test@abc.com',
    preLoginAuth: '<preLoginAuthToken>',
    next: AuthScreen.GET_PASSWORD,
  });

  mockAdapter.onPost(`/email`).reply<EnterEmailResponse>(200, {
    message: 'success',
    errorMessage: '',
    name: 'Anna White',
    preLoginAuth: '<preLoginAuthToken>',
    next: AuthScreen.GET_PASSWORD,
  });

  mockAdapter.onPost(`/login`).reply<LoginResponse>(200, {
    message: 'success',
    errorMessage: '',
    context: AppContext.ADMIN,
    postLoginAuth: '<postLoginAuthToken>',
    next: 'home',
  });

  mockAdapter.onPost(`/logout`).reply<LogoutResponse>(200, {
    message: 'success',
  });

  mockAdapter.onPost(`/resend_verification`).reply<ResendVerificationCodeResponse>(200, {
    message: 'success',
  });

  mockAdapter.onPost(`/verify`).reply<VerifyEmailResponse>(200, {
    message: 'success',
    errorMessage: '',
    next: AuthScreen.RESET_PASSWORD,
  });

  mockAdapter.onPost(`/set-password`).reply<SetPasswordResponse>(200, {
    message: 'success',
    errorMessage: '',
    context: AppContext.ADMIN,
    postLoginAuth: '<postLoginAuthToken>',
    next: 'home',
  });

  mockAdapter.onPost(`/reset-password`).reply<ResetPasswordResponse>(200, {
    message: 'success',
    errorMessage: '',
    context: AppContext.ADMIN,
    postLoginAuth: '<postLoginAuthToken>',
    next: 'home',
  });

  mockAdapter.onPost(`/forgot-password`).reply<ForgotPasswordResponse>(200, {
    message: 'success',
    errorMessage: '',
    next: AuthScreen.FORGOT_PASSWORD,
  });
}
