import { AES, enc } from 'crypto-js';

export enum AppContext {
  ADMIN = 'admin',
  COURSE = 'course',
}

export interface Session {
  name: string;
  emailId: string;
  context: AppContext;
  /** authorization token received from server after providing email */
  preLoginAuth?: string;
  /** authorization token received from server after login */
  postLoginAuth?: string;
}

const SESSION_KEY = process.env.REACT_APP_SESSION_KEY;

/**
 * Store session data to localStorage
 * for persistent session storage
 * @param session session data
 */
export function saveSession(session: Session) {
  const sessionStr = JSON.stringify(session);
  const sessionCipher = AES.encrypt(sessionStr, SESSION_KEY).toString();
  localStorage.setItem('session', sessionCipher);
}

export function getSession(): Session | null {
  const sessionCipher = localStorage.getItem('session');

  if (!sessionCipher) return null;

  try {
    const sessionStr = AES.decrypt(sessionCipher, SESSION_KEY).toString(enc.Utf8);
    return JSON.parse(sessionStr);
  } catch (error) {
    console.warn('Malformed session data');
    return null;
  }
}

export function clearSession() {
  localStorage.clear();
}
