import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../app/store';
import { selectAffectedCourseSummary } from '../home/selectors';
import { debounce } from '../utils/timers';
import { fetchStudentsForCourse, resetAffectedCourseStudentList, setAffectedCourse } from './reducer';
import { selectAffectedCourse, selectAffectedCourseStudents } from './selectors';

export const useCourseStudentList = (courseId: string) => {
  const isFirstLoad = useRef(true);
  const dispatch = useDispatch<AppDispatch>();

  const affectedCourse = useSelector(selectAffectedCourse);
  const studentData = useSelector(selectAffectedCourseStudents);
  const courseSummary = useSelector(selectAffectedCourseSummary(courseId));

  const [searchTerm, setSearchTerm] = useState('');

  const refreshListData = useCallback(
    async (searchTerm: string, limit: number, total: number) => {
      dispatch(resetAffectedCourseStudentList({ total }));
      if (searchTerm.length === 0 || searchTerm.length > 2) {
        /**
         * if searchTerm has 0 or more than 2 characters
         * then refresh list data by sending server request
         */
        await dispatch(
          fetchStudentsForCourse({
            courseId,
            limit,
            searchTerm,
            skip: 0,
          })
        );
      }
    },
    [courseId, dispatch]
  );

  const refreshListDataDebounced = useMemo(() => debounce(refreshListData, 500), [refreshListData]);

  const loadMoreItems = useMemo(() => {
    return debounce((startIndex: number, endIndex: number) => {
      if (searchTerm.length > 0 && searchTerm.length < 3) {
        /**
         * don't fetch data when search text length is between 0 and 3
         * but show list as if data is being fetched
         */
        return;
      }
      dispatch(
        fetchStudentsForCourse({
          courseId,
          limit: endIndex - startIndex + 1,
          searchTerm: searchTerm,
          skip: startIndex,
        })
      );
    }, 500);
  }, [courseId, dispatch, searchTerm]);

  useEffect(() => {
    isFirstLoad.current = true;
  }, [courseId]);

  useEffect(() => {
    if (courseSummary?.numStudents === undefined) return;

    if (isFirstLoad.current) {
      dispatch(setAffectedCourse(null));
      refreshListData('', 100, courseSummary.numStudents).then(() => {
        isFirstLoad.current = false;
      });
    } else {
      const searchLength = searchTerm.length;
      const limit = searchLength > 0 ? 50 : 100;
      const total = searchLength > 0 ? 50 : courseSummary.numStudents;
      refreshListDataDebounced(searchTerm, limit, total);
    }
  }, [courseSummary?.numStudents, dispatch, refreshListData, refreshListDataDebounced, searchTerm]);

  return {
    loadMoreItems,
    refreshListData,
    refreshListDataDebounced,
    searchTerm,
    setSearchTerm,
    studentData,
    courseSummary,
    affectedCourse,
  };
};
