import { AcadlyUser, CovidStatus, Proximity } from '../utils/types';

export interface AffectedCourseSummary {
  courseId: string;
  code: string;
  numStudents: number;
}

export interface CourseCovidSummary {
  numRecord: number;
  numLectures: number;
  numAffected: number;
  affected: AffectedCourseSummary[];
}

export interface StudentCovidSummary {
  numEnrolled: number;
  numExposed: number;
  numConcern: number;
}

export interface CovidSummary {
  students: StudentCovidSummary;
  courses: CourseCovidSummary;
}

export interface StudentProfile extends AcadlyUser {
  status: CovidStatus;
  proximity: Proximity;
  coursesEnrolledIn: number;
}

export interface StudentEnrolledCourse {
  courseId: string;
  code: string;
  title: string;
  admin: AcadlyUser;
  numConcern: number;
  lecturesAttended: number;
}

export interface ExposureInstance {
  classTime: UnixTime;
  proximity: Proximity;
}

export interface ExposedStudent extends AcadlyUser {
  course: string;
  testedOn: UnixTime;
  instances: ExposureInstance[];
}

export interface CovidTestRecord {
  /** 0, if status !== "positive" || status !== "recovered" */
  testDate: UnixTime;
  /** 0, if status !== "positive" || status !== "recovered" */
  traceStartDate: UnixTime;
  /** 0, if status !== "recovered" */
  recoveredOn: UnixTime;
  history: string[];
}

export enum CovidTestRecordAction {
  POSITIVE = 'positive',
  RETRACT = 'retract',
  RECOVERED = 'recovered',
}
