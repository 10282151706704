import { AcadlyUser, CovidStatus, Proximity } from '../utils/types';

export enum StudentCovidDataFilter {
  ENROLLED = 'enrolled',
  EXPOSED = 'exposed',
  CONCERN = 'concern',
}

export const filterByToNumMap = {
  [StudentCovidDataFilter.CONCERN]: 'numConcern',
  [StudentCovidDataFilter.ENROLLED]: 'numEnrolled',
  [StudentCovidDataFilter.EXPOSED]: 'numExposed',
} as const;

export interface StudentCovidData extends AcadlyUser {
  coursesEnrolledIn: number;
  coursesAffected: number;
  status: CovidStatus;
  /** 0 means not exposed */
  firstExposure: UnixTime;
  proximity: Proximity;
  studentOfConcern: NumericBoolean;
  /** 0 means not tested */
  testDate: UnixTime;
  studentsExposed: number;
}
