import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchCovidSummary } from '../home/reducer';
import { getStudentCovidData, GetStudentCovidDataParams } from './api';
import { StudentCovidData, StudentCovidDataFilter } from './types';

export const STUDENTS = 'students';

interface StudentList {
  /**
   * Total elements in the list.
   *
   * NOTE: This number could possibly be greater than the number of fetched items.
   */
  total: number;
  /** map of student-userId and student-covid-data */
  students: Dictionary<StudentCovidData>;
  /**
   * a sparse array of student-user-ids, empty holes (undefined values)
   * represents that student data is yet to be fetched from server
   */
  listOrder: SparseArray<string>;
}

export type StudentsState = {
  [key in StudentCovidDataFilter]: StudentList;
};

type ResetListAction = PayloadAction<
  {
    [key in StudentCovidDataFilter]?: {
      total: number;
    };
  }
>;

type UpdateListAction = PayloadAction<
  {
    [key in StudentCovidDataFilter]?: {
      startIndex: number;
      students: StudentCovidData[];
    };
  }
>;

const initialState: StudentsState = {
  concern: {
    total: 0,
    students: {},
    listOrder: [],
  },
  enrolled: {
    total: 0,
    students: {},
    listOrder: [],
  },
  exposed: {
    total: 0,
    students: {},
    listOrder: [],
  },
};

export const studentsSlice = createSlice({
  name: STUDENTS,

  initialState,

  reducers: {
    resetList(state, action: ResetListAction) {
      const keys = Object.keys(action.payload) as StudentCovidDataFilter[];

      for (const key of keys) {
        const studentList = state[key];
        const total = action.payload[key]?.total;
        if (total !== undefined) {
          studentList.total = total;
          studentList.listOrder = Array(total);
          studentList.students = {};
        }
      }
    },
    updateList(state, action: UpdateListAction) {
      const keys = Object.keys(action.payload) as StudentCovidDataFilter[];

      for (const key of keys) {
        const studentList = state[key];
        const data = action.payload[key];
        if (data !== undefined) {
          data.students.forEach((student, index) => {
            studentList.students[student.userId] = student;
            studentList.listOrder[data.startIndex + index] = student.userId;
          });
        }
      }
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchCovidSummary.fulfilled, (state, action) => {
      const { students } = action.payload;

      state.concern.total = students.numConcern;
      state.concern.listOrder = Array(students.numConcern);

      state.enrolled.total = students.numEnrolled;
      state.enrolled.listOrder = Array(students.numEnrolled);

      state.exposed.total = students.numExposed;
      state.exposed.listOrder = Array(students.numExposed);
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetList, updateList } = studentsSlice.actions;

export const fetchStudentCovidData = createAsyncThunk(
  `${STUDENTS}/fetchStudentCovidData`,
  async (requestParams: GetStudentCovidDataParams, { dispatch }) => {
    const response = await getStudentCovidData(requestParams);

    dispatch(
      updateList({
        [requestParams.filterBy]: {
          startIndex: requestParams.skip,
          students: response.students,
        },
      })
    );

    return { requestParams, response };
  }
);

const studentsReducer = studentsSlice.reducer;

export default studentsReducer;
