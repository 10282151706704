import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getStudentsForCourse, GetStudentsForCourseParams } from './api';
import { AffectedCourse, StudentCovidDataForCourse } from './types';

const COURSES = 'courses';

interface StudentList {
  course: AffectedCourse | null;
  /**
   * Total elements in the list.
   *
   * NOTE: This number could possibly be greater than the number of fetched items.
   */
  total: number;
  /** map of student-userId and student-covid-data */
  students: Dictionary<StudentCovidDataForCourse>;
  /**
   * a sparse array of student-user-ids, empty holes (undefined values)
   * represents that student data is yet to be fetched from server
   */
  listOrder: SparseArray<string>;
}

interface CoursesState {
  affected: StudentList;
}

const initialState: CoursesState = {
  affected: {
    course: null,
    total: 0,
    students: {},
    listOrder: [],
  },
};

type ResetAffectedCourseStudentListAction = PayloadAction<{
  total: number;
}>;

type UpdateAffectedCourseStudentListAction = PayloadAction<{
  startIndex: number;
  students: StudentCovidDataForCourse[];
}>;

const coursesSlice = createSlice({
  name: COURSES,

  initialState,

  reducers: {
    setAffectedCourse(state, action: PayloadAction<AffectedCourse | null>) {
      state.affected.course = action.payload;
    },
    resetAffectedCourseStudentList(state, action: ResetAffectedCourseStudentListAction) {
      const studentList = state.affected;
      const total = action.payload.total;

      studentList.total = total;
      studentList.listOrder = Array(total);
      studentList.students = {};
    },
    updateAffectedCourseStudentList(state, action: UpdateAffectedCourseStudentListAction) {
      const studentList = state.affected;
      const data = action.payload;

      data.students.forEach((student, index) => {
        studentList.students[student.userId] = student;
        studentList.listOrder[data.startIndex + index] = student.userId;
      });
    },
  },
});

export const { resetAffectedCourseStudentList, setAffectedCourse, updateAffectedCourseStudentList } =
  coursesSlice.actions;

export const fetchStudentsForCourse = createAsyncThunk(
  `${COURSES}/fetchStudentsForCourse`,
  async (requestParams: GetStudentsForCourseParams, { dispatch }) => {
    const response = await getStudentsForCourse(requestParams);

    dispatch(setAffectedCourse(response.course));
    dispatch(
      updateAffectedCourseStudentList({
        startIndex: requestParams.skip,
        students: response.students,
      })
    );

    return { requestParams, response };
  }
);

const coursesReducer = coursesSlice.reducer;

export default coursesReducer;
