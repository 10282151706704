import request from '../utils/request';
import { AppContext } from '../utils/session';
import { AuthScreen } from './types';

export interface GetUniversityDataResponse {
  message: 'success' | 'error';
  errorMessage: string;
  /** will be populated in case message === "success" */
  clusterUrl: string;
  universityId: string;
  universityName: string;
  logoUrl: string;
}

export async function getUniversityData(university: string) {
  const response = await request.get<GetUniversityDataResponse>('/get_ct_cluster', {
    baseURL: process.env.REACT_APP_GATEWAY_URL,
    params: { university },
  });
  return response.data;
}

export interface GetLTIAccessDataResponse {
  message: 'success' | 'error';
  /**
   * only when message === "error"
   */
  errorMessage: string;
  /**
   * only if message === "success" and
   * next === AuthScreen.VERIFY_EMAIL or AuthScreen.GET_PASSWORD
   */
  preLoginAuth: string;
  /**
   * only if message === "success" and
   * next === AuthScreen.VERIFY_EMAIL or AuthScreen.GET_PASSWORD
   */
  name: string;
  /**
   * only if message === "success" and
   * next === AuthScreen.VERIFY_EMAIL or AuthScreen.GET_PASSWORD
   */
  emailId: string;
  /** only if message === "success" */
  next: AuthScreen.EMAIL | AuthScreen.VERIFY_EMAIL | AuthScreen.GET_PASSWORD;
}

export async function getLTIAccessData(accessCode: string) {
  const response = await request.post<GetLTIAccessDataResponse>('/lti_access', { accessCode });
  return response.data;
}

interface EnterEmailRequestData {
  emailId: string;
  universityId: string;
}

export interface EnterEmailResponse {
  message: 'success' | 'error';
  errorMessage: string;
  /** only if message === "success" */
  name: string;
  /** only if message === "success" */
  preLoginAuth: string;
  /** only if message === "success" */
  next: AuthScreen.VERIFY_EMAIL | AuthScreen.GET_PASSWORD;
}

export async function enterEmail(data: EnterEmailRequestData) {
  const response = await request.post<EnterEmailResponse>('/email', data);
  return response.data;
}

interface LoginRequestData {
  password: string;
}

export interface LoginResponse {
  message: 'success' | 'error';
  errorMessage: string;
  context: AppContext;
  postLoginAuth: string;
  next: 'home';
}

export async function login(data: LoginRequestData) {
  const response = await request.post<LoginResponse>('/login', data);
  return response.data;
}

export interface LogoutResponse {
  message: 'success';
}

export async function logout() {
  const response = await request.post<LogoutResponse>('/logout');
  return response.data;
}

export interface ResendVerificationCodeResponse {
  message: 'success';
}

export async function resendVerificationCode() {
  const response = await request.post('/resend_verification');
  return response.data;
}

interface VerifyEmailRequestData {
  verificationCode: string;
}

export interface VerifyEmailResponse {
  message: 'success' | 'error';
  errorMessage: string;
  /** only if message === "success" */
  next: AuthScreen.SET_PASSWORD | AuthScreen.RESET_PASSWORD;
}

export async function verifyEmail(data: VerifyEmailRequestData) {
  const response = await request.post<VerifyEmailResponse>('/verify', data);
  return response.data;
}

interface SetPasswordRequestData {
  password: string;
}

export interface SetPasswordResponse {
  message: 'success' | 'error';
  errorMessage: string;
  context: AppContext;
  postLoginAuth: string;
  next: 'home';
}

export async function setPassword(data: SetPasswordRequestData) {
  const response = await request.post<SetPasswordResponse>('/set-password', data);
  return response.data;
}

interface ResetPasswordRequestData {
  password: string;
}

export interface ResetPasswordResponse {
  message: 'success' | 'error';
  errorMessage: string;
  context: AppContext;
  postLoginAuth: string;
  next: 'home';
}

export async function resetPassword(data: ResetPasswordRequestData) {
  const response = await request.post<ResetPasswordResponse>('/reset-password', data);
  return response.data;
}

export interface ForgotPasswordResponse {
  message: 'success';
  errorMessage: string;
  next: AuthScreen.FORGOT_PASSWORD;
}

export async function forgotPassword() {
  const response = await request.post<ForgotPasswordResponse>('/forgot-password');
  return response.data;
}
