import request from '../utils/request';
import {
  CovidSummary,
  CovidTestRecord,
  CovidTestRecordAction,
  ExposedStudent,
  StudentEnrolledCourse,
  StudentProfile,
} from './types';

export interface GetCovidSummaryResponse extends CovidSummary {
  message: string;
}

export async function getCovidSummary() {
  const response = await request.get<GetCovidSummaryResponse>('/summary');
  return response.data;
}

export interface GetStudentCoursesResponse {
  message: 'success';
  student: StudentProfile;
  courses: StudentEnrolledCourse[];
}

export async function getStudentCourses(studentId: string) {
  const response = await request.get<GetStudentCoursesResponse>('/students/courses', {
    params: { studentId },
  });
  return response.data;
}

export interface GetExposedStudentsResponse {
  message: 'success';
  student: StudentProfile;
  exposure: ExposedStudent[];
}

export async function getExposedStudents(studentId: string) {
  const response = await request.get<GetExposedStudentsResponse>('/students/exposure', {
    params: { studentId },
  });
  return response.data;
}

export interface GetStudentCovidTestRecordResponse {
  message: 'success';
  student: StudentProfile;
  testRecord: CovidTestRecord;
}

export async function getStudentCovidTestRecord(studentId: string) {
  const response = await request.get<GetStudentCovidTestRecordResponse>('/students/test_record', {
    params: { studentId },
  });
  return response.data;
}

interface CommonTestRecordData {
  userId: string;
  notifyAdmins?: NumericBoolean;
}

interface PositiveTestRecordData extends CommonTestRecordData {
  action: CovidTestRecordAction.POSITIVE;
  /** format: YYYY-MM-DD */
  testedOn: string;
  /** format: YYYY-MM-DD */
  traceStartDate: string;
}

interface RetractTestRecordData extends CommonTestRecordData {
  action: CovidTestRecordAction.RETRACT;
}

interface RecoveredTestRecordData extends CommonTestRecordData {
  action: CovidTestRecordAction.RECOVERED;
  /** format: YYYY-MM-DD */
  recoveredOn: string;
}

export type UpdateCovidTestRecordData =
  | PositiveTestRecordData
  | RetractTestRecordData
  | RecoveredTestRecordData;

export async function updateCovidTestRecord(data: UpdateCovidTestRecordData) {
  const response = await request.put<GetStudentCovidTestRecordResponse>('/students/update_status', data);
  return response.data;
}
