import { AxiosError } from 'axios';

import { store } from '../app/store';
import { setSession } from '../auth/reducer';
import request from './request';

export default function registerInterceptors() {
  request.interceptors.request.use((config) => {
    const { session, university } = store.getState().auth;

    if (university?.cluster && !config.baseURL) {
      config.baseURL = university.cluster;
    }

    const token = session?.postLoginAuth || session?.preLoginAuth;

    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (university?.id) {
      config.headers['X-Organization'] = university.id;
    }

    return config;
  });

  request.interceptors.response.use(
    (response) => response,
    (error) => {
      const err = error as AxiosError;
      if (err.response?.status === 401 || err.response?.status === 403) {
        store.dispatch(setSession(null));
      }
      return Promise.reject(error);
    }
  );
}
