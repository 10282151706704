import request from '../utils/request';
import { PaginationData } from '../utils/types';
import { StudentCovidData, StudentCovidDataFilter } from './types';

export interface GetStudentCovidDataParams extends PaginationData {
  filterBy: StudentCovidDataFilter;
}

export interface GetStudentCovidDataResponse {
  message: string;
  students: StudentCovidData[];
}

export async function getStudentCovidData({
  filterBy,
  limit,
  skip,
  searchTerm,
}: GetStudentCovidDataParams): Promise<GetStudentCovidDataResponse> {
  const response = await request.get(`/students/all/${filterBy}`, {
    params: { limit, skip, searchTerm },
  });
  return response.data;
}
