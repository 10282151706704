import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paper, TextField } from '@material-ui/core';

import LoaderButton from '../components/LoaderButton';
import { AppContext } from '../utils/session';
import { isValidEmail } from '../utils/validate';
import { enterEmail } from './api';
import { setScreen, setSession } from './reducer';
import { selectUniversity } from './selectors';
import { useAuthStyles } from './styles';

const GetEmail = () => {
  const styles = useAuthStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const university = useSelector(selectUniversity);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();

    if (!university?.id) return;

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);

    try {
      const { message, errorMessage, name, preLoginAuth, next } = await enterEmail({
        emailId: email,
        universityId: university.id,
      });

      if (message === 'error') {
        setError(errorMessage);
        setIsSubmitting(false);
        return;
      }

      dispatch(
        setSession({
          name,
          preLoginAuth,
          emailId: email,
          context: AppContext.COURSE,
        })
      );

      dispatch(setScreen(next));
    } catch (error) {
      setIsSubmitting(false);
      setError('Some error occured! Please try after sometime.');
    }
  };

  return (
    <Paper elevation={3} component="form" className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.formTitle}>Enter your email address</h2>
      <p className={styles.formDescription} />
      <TextField
        required
        fullWidth
        error={Boolean(error)}
        helperText={error}
        type="text"
        variant="standard"
        value={email}
        onChange={handleChange}
        autoComplete="username"
        autoCapitalize="off"
        placeholder="Type here"
        inputProps={{ className: styles.textCenter }}
        FormHelperTextProps={{ className: styles.textCenter }}
      />
      <div className={styles.actions}>
        <div />
        <LoaderButton isLoading={isSubmitting} color="primary" variant="contained" type="submit">
          Next
        </LoaderButton>
      </div>
    </Paper>
  );
};

export default GetEmail;
