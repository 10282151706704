import { FC } from 'react';

import { makeStyles } from '@material-ui/core';

import Header from './Header';
import Nav from './Nav';
import PoweredBy from './PoweredBy';

export const navWidth = 376;
export const headerHeight = 80;
export const poweredByHeight = 67;

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'grid',
    width: '100vw',
    minWidth: 1366,
    height: '100vh',
    overflow: 'hidden',
    gap: theme.spacing(3),
    gridTemplateColumns: `${navWidth}px auto`,
    gridTemplateRows: `${headerHeight}px auto ${poweredByHeight}px`,
  },
  header: {
    gridColumn: '1/3',
    gridRow: 1,
  },
  nav: {
    gridColumn: 1,
    gridRow: 2,
    overflow: 'auto',
  },
  poweredBy: {
    gridColumn: 1,
    gridRow: 3,
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(1.5),
  },
  main: {
    gridColumn: 2,
    gridRow: '2/4',
    paddingRight: theme.spacing(2),
    overflow: 'auto',
  },
}));

const Layout: FC = ({ children }) => {
  const styles = useStyle();
  return (
    <div className={styles.root}>
      <Header className={styles.header} />
      <Nav className={styles.nav} />
      <main className={styles.main}>{children}</main>
      <PoweredBy className={styles.poweredBy} />
    </div>
  );
};

export default Layout;
