import { RootState } from '../app/store';

export const selectIsHomeLoading = (state: RootState) => state.home.isLoading;

export const selectStudentSummary = (state: RootState) => state.home.students;

export const selectAffectedCourseSummary = (courseId: string) => (state: RootState) => {
  return state.home.courses.affected.find((course) => course.courseId === courseId);
};

export const selectCoursesSummary = (state: RootState) => state.home.courses;

export const selectStudentDialogState = (state: RootState) => state.home.studentDialog;

export const selectEnrolledCourses = (state: RootState) => state.home.studentDialog.enrolledCourses;

export const selectExposedStudents = (state: RootState) => state.home.studentDialog.exposedStudents;
