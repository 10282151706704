import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#FFFFFF',
  },
  acadlyLoader: {
    position: 'absolute',
    width: 60,
    height: 60,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  bg: {
    animation: '$loaderEffect 1s infinite step-end',
    transformOrigin: 'center',
  },
  '@keyframes loaderEffect': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '25%': {
      transform: 'rotate(90deg)',
    },
    '50%': {
      transform: 'rotate(180deg)',
    },
    '75%': {
      transform: 'rotate(270deg)',
    },
  },
});

interface Props {
  className?: string;
}

const Loader = ({ className }: Props) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, className)}>
      <svg
        className={styles.acadlyLoader}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path
            d="M14.1362 21.2733V15.468C14.1362 14.8293 14.6579 14.3067 15.2953 14.3067H19.9308C20.5683 14.3067 21.0899 14.8293 21.0899 15.468V20.112C21.0899 20.7507 20.5683 21.2733 19.9308 21.2733H14.1362V21.2733ZM12.398 21.8544C12.398 22.493 12.9197 23.0156 13.5571 23.0156H21.669C22.3065 23.0156 22.8281 22.493 22.8281 21.8544V13.7275C22.8281 13.0888 22.3065 12.5662 21.669 12.5662H13.5571C12.9197 12.5662 12.398 13.0888 12.398 13.7275V21.8544V21.8544Z"
            fill="#006899"
          />
          <path
            d="M6.02434 16.4603L7.90848 20.2356C8.19379 20.8063 7.90566 21.2742 7.26819 21.2742H7.18345C6.54599 21.2742 5.50269 21.2742 4.86523 21.2742H4.78049C4.14302 21.2742 3.85489 20.8073 4.1402 20.2356L6.02434 16.4603V16.4603ZM6.54222 13.6039C6.25691 13.0332 5.79082 13.0332 5.50552 13.6039L1.3267 21.9761C1.04139 22.5468 1.32952 23.0147 1.96699 23.0147H4.86429C5.50175 23.0147 6.54504 23.0147 7.18251 23.0147H10.0798C10.7173 23.0147 11.0054 22.5477 10.7201 21.9761L6.54128 13.6039H6.54222Z"
            fill="#D11250"
          />
          <path
            d="M6.02434 4.84967L7.90848 8.62493C8.19379 9.19566 7.90566 9.66355 7.26819 9.66355H7.18345C6.54599 9.66355 5.50269 9.66355 4.86523 9.66355H4.78049C4.14302 9.66355 3.85489 9.1966 4.1402 8.62493L6.02434 4.84967V4.84967ZM6.54222 1.99417C6.25691 1.42345 5.79082 1.42345 5.50552 1.99417L1.3267 10.3663C1.04139 10.9371 1.32952 11.405 1.96699 11.405H4.86429C5.50175 11.405 6.54504 11.405 7.18251 11.405H10.0798C10.7173 11.405 11.0054 10.938 10.7201 10.3663L6.54128 1.99417H6.54222Z"
            fill="#DB5800"
          />
          <path
            d="M17.6126 0.955551C14.7323 0.955551 12.398 3.2941 12.398 6.17979C12.398 9.06547 14.7332 11.404 17.6126 11.404C20.492 11.404 22.8272 9.06453 22.8272 6.17979C22.8272 3.2941 20.492 0.955551 17.6126 0.955551V0.955551ZM17.6126 9.66355C15.6955 9.66355 14.1362 8.10138 14.1362 6.18073C14.1362 4.26008 15.6955 2.69791 17.6126 2.69791C18.9892 2.69791 20.1822 3.50446 20.7444 4.67044L17.7284 6.18167L20.7444 7.69291C20.1813 8.85888 18.9892 9.66544 17.6126 9.66544V9.66355Z"
            fill="#008F68"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect className={styles.bg} width="12" height="12" fill="#DB5800" />
          <rect className={styles.bg} x="12" width="12" height="12" fill="#008F68" />
          <rect className={styles.bg} x="12" y="12" width="12" height="12" fill="#006899" />
          <rect className={styles.bg} y="12" width="12" height="12" fill="#D11250" />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
