import MockAdapter from 'axios-mock-adapter/types';
import { lib } from 'crypto-js';

import { CovidStatus, Proximity } from '../utils/types';
import { GetStudentCovidDataResponse } from './api';
import { StudentCovidData } from './types';

function randomId() {
  return lib.WordArray.random(2).toString();
}

function getSeedStudentData(): StudentCovidData[] {
  return [
    {
      userId: randomId(),
      avatar: '',
      name: 'Alan Cooper',
      emailId: 'alan.cooper@uic.edu',
      coursesAffected: 2,
      coursesEnrolledIn: 2,
      proximity: Proximity.VERY_CLOSE,
      status: CovidStatus.POSITIVE,
      firstExposure: 1629743400,
      studentOfConcern: 1,
      studentsExposed: 196,
      testDate: 1629743400,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'James Gouse',
      emailId: 'jamesg@uic.edu',
      coursesAffected: 1,
      coursesEnrolledIn: 1,
      proximity: Proximity.CLOSE,
      status: CovidStatus.EXPOSED,
      firstExposure: 1629484200,
      studentOfConcern: 1,
      studentsExposed: 102,
      testDate: 1629484200,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Alexis Sears',
      emailId: 'asears1@uic.edu',
      coursesAffected: 2,
      coursesEnrolledIn: 2,
      proximity: Proximity.MODERATE,
      status: CovidStatus.EXPOSED,
      firstExposure: 1629397800,
      studentOfConcern: 1,
      studentsExposed: 33,
      testDate: 1629397800,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Anna White',
      emailId: 'aw32@uic.edu',
      coursesAffected: 1,
      coursesEnrolledIn: 1,
      proximity: Proximity.FAR,
      status: CovidStatus.EXPOSED,
      firstExposure: 1629657000,
      studentOfConcern: 1,
      studentsExposed: 24,
      testDate: 1629657000,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Skylar Dias',
      emailId: 'skylard@uic.edu',
      coursesAffected: 2,
      coursesEnrolledIn: 2,
      proximity: Proximity.VERY_FAR,
      status: CovidStatus.EXPOSED,
      firstExposure: 1629657000,
      studentOfConcern: 1,
      studentsExposed: 62,
      testDate: 1629657000,
    },
  ];
}

function getStudentData(count: number) {
  const data = getSeedStudentData();
  const result: StudentCovidData[] = [];

  const repeat = Math.floor(count / data.length);
  const remainder = count % data.length;

  for (let i = 0; i < repeat; i++) {
    result.push(...getSeedStudentData());
  }

  result.push(...data.slice(0, remainder));

  return result;
}

export default function registerStudentsMocks(mockAdapter: MockAdapter) {
  mockAdapter
    .onGet(/^\/students\/all\/(enrolled|exposed|concern)$/)
    .reply<GetStudentCovidDataResponse>(async (config) => {
      const { limit } = config.params;

      return [
        200,
        {
          message: 'success',
          students: getStudentData(limit),
        },
      ];
    });
}
