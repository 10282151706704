import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paper } from '@material-ui/core';

import LoaderButton from '../components/LoaderButton';
import PasswordInput from '../components/PasswordInput';
import { isValidPassword } from '../utils/validate';
import { setPassword as setPasswordAPI } from './api';
import { setSession } from './reducer';
import { selectSession } from './selectors';
import { useAuthStyles } from './styles';

const SetPassword = () => {
  const styles = useAuthStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const session = useSelector(selectSession);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();

    if (!session) return;

    if (!isValidPassword(password)) {
      setError('Minimum 8 characters are required');
      return;
    }

    setIsSubmitting(true);

    try {
      const { message, errorMessage, context, postLoginAuth } = await setPasswordAPI({ password });

      if (message === 'error') {
        setError(errorMessage);
        setIsSubmitting(false);
        return;
      }

      dispatch(
        setSession({
          ...session,
          context,
          postLoginAuth,
          preLoginAuth: '',
        })
      );
    } catch (error) {
      setIsSubmitting(false);
      setError('Some error occured! Please try after sometime.');
    }
  };

  return (
    <Paper elevation={3} component="form" className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.formTitle}>Set a password</h2>
      <p className={styles.formDescription}>
        Hi {session?.name}! Please set a password. It should have at least 8 characters
      </p>
      <input
        readOnly
        className={styles.hidden}
        type="email"
        autoComplete="username"
        value={session?.emailId}
      />
      <PasswordInput
        fullWidth
        error={error}
        inputProps={{ className: styles.textCenter }}
        formHelperTextProps={{ className: styles.textCenter }}
        autoComplete="new-password"
        placeholder="Type your password here"
        value={password}
        onChange={handleChange}
      />
      <div className={styles.actions}>
        <div />
        <LoaderButton isLoading={isSubmitting} color="primary" variant="contained" type="submit">
          Next
        </LoaderButton>
      </div>
    </Paper>
  );
};

export default SetPassword;
