import { makeStyles } from '@material-ui/core';

import SearchInput from '../components/SearchInput';
import VirtualList from '../components/VirtualList';
import { colors } from '../utils/theme';
import ExposedStudentCell, { ExposedStudentCellHeight } from './ExposedStudentCell';
import { useStudentList } from './hooks';
import { StudentCovidDataFilter } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: colors.grey1,
  },
  search: {
    flex: 1,
    maxWidth: 480,
    marginRight: theme.spacing(3),
  },
}));

const ExposedStudents = () => {
  const styles = useStyles();

  const { loadMoreItems, searchTerm, setSearchTerm, studentData, studentSummary } = useStudentList(
    StudentCovidDataFilter.EXPOSED
  );

  if (!studentSummary.numExposed) {
    return (
      <div className={styles.root}>
        <h2 className={styles.title}>Exposed students</h2>
        <p>No students are exposed to any student of concern</p>
      </div>
    );
  }

  return (
    <VirtualList
      className={styles.root}
      data={studentData}
      header={
        <>
          <h2 className={styles.title}>Exposed students</h2>
          <div className={styles.header}>
            <SearchInput
              className={styles.search}
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search by name or email address"
            />
          </div>
        </>
      }
      isItemLoaded={(index) => Boolean(studentData[index])}
      itemCount={studentData.length}
      itemKey={(index, data) => data[index]?.userId || `concerned-${index}`}
      loadMoreItems={loadMoreItems}
      rowHeight={ExposedStudentCellHeight}
    >
      {ExposedStudentCell}
    </VirtualList>
  );
};

export default ExposedStudents;
