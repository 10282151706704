import { useMemo } from 'react';

import { colors as materialColors, makeStyles, Tooltip } from '@material-ui/core';

import clsx from 'clsx';
import { format } from 'date-fns';

import UserCell, { UserCellLoading } from '../components/UserCell';
import { colors } from '../utils/theme';
import { Proximity } from '../utils/types';
import { ExposedStudent } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: '"user tested-on course" "instances instances instances"',
    gridTemplateColumns: 'minmax(180px, 60%) 160px 160px',
    border: '1px solid #EB5757',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 1, 1),
    gap: theme.spacing(2),

    '&.loading': {
      borderColor: '#ced4da',
    },
  },
  label: {
    height: 16,
    lineHeight: 1.33,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'rgba(51, 51, 51, 0.5)',
  },
  value: {
    ...theme.mixins.ellipsize,
    height: 32,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: colors.grey1,
  },
  user: {
    gridArea: 'user',
  },
  testedOn: {
    gridArea: 'tested-on',
    textAlign: 'right',
  },
  course: {
    gridArea: 'course',
    textAlign: 'right',
  },
  instances: {
    gridArea: 'instances',
  },
  dateCell: {
    width: 120,
    height: 24,
    borderRadius: 4,
    display: 'inline-block',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.grey1,
    backgroundColor: colors.grey4,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.71,

    '&.loading': {
      backgroundColor: '#ced4da',
    },

    '&.very-close': {
      color: '#fff',
      backgroundColor: materialColors.red[500],
    },

    '&.close': {
      color: '#fff',
      backgroundColor: materialColors.red[400],
    },

    '&.moderate': {
      color: '#fff',
      backgroundColor: materialColors.orange[500],
    },

    '&.far': {
      color: colors.grey1,
      backgroundColor: materialColors.yellow['A700'],
    },

    '&.very-far': {
      color: '#fff',
      backgroundColor: materialColors.grey[500],
    },
  },
  loaderStrip: theme.mixins.loaderStrip,
}));

const proximityLabelMap: {
  [key in Proximity]: string;
} = {
  [Proximity.CLOSE]: 'Close',
  [Proximity.DEFAULT]: '',
  [Proximity.FAR]: 'Far',
  [Proximity.MODERATE]: 'Moderate',
  [Proximity.UNKNOWN]: 'Unknown',
  [Proximity.VERY_CLOSE]: 'Very close',
  [Proximity.VERY_FAR]: 'Very far',
};

interface ExposedStudentCellLoadingProps {
  className?: string;
}

export const ExposedStudentCellLoading = ({ className }: ExposedStudentCellLoadingProps) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, 'loading', className)}>
      <UserCellLoading className={styles.user} />
      <div className={styles.testedOn}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'half', 'right')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.loaderStrip, 'tall', 'right')} />
        </div>
      </div>
      <div className={styles.course}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'half', 'right')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.loaderStrip, 'tall', 'right')} />
        </div>
      </div>
      <div className={styles.instances}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'half')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.dateCell, 'loading')} />
          <div className={clsx(styles.dateCell, 'loading')} />
          <div className={clsx(styles.dateCell, 'loading')} />
        </div>
      </div>
    </div>
  );
};

interface Props extends ExposedStudent {
  className?: string;
}

const ExposedStudentCell = ({ className, name, emailId, avatar, course, testedOn, instances }: Props) => {
  const styles = useStyles();

  const formatedTestedOn = useMemo(() => format(testedOn * 1000, 'do MMMM, ‘yy'), [testedOn]);

  return (
    <div className={clsx(styles.root, className)}>
      <UserCell className={styles.user} name={name} email={emailId} avatarURL={avatar} />
      <div className={styles.testedOn}>
        <div className={styles.label}>Tested ON</div>
        <div className={styles.value}>{formatedTestedOn}</div>
      </div>
      <div className={styles.course}>
        <div className={styles.label}>Shared course</div>
        <div className={styles.value}>{course}</div>
      </div>
      <div className={styles.instances}>
        <div className={styles.label}>instances of shared classrooms</div>
        {instances.map((instance, index) => (
          <Tooltip
            placement="top"
            key={instance.classTime + index}
            title={`Proximity: ${proximityLabelMap[instance.proximity]}`}
          >
            <div className={clsx(styles.dateCell, instance.proximity)}>
              {format(instance.classTime * 1000, 'do MMMM, ‘yy')}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default ExposedStudentCell;
