import { colors as materialColors, makeStyles } from '@material-ui/core';

import { ReactComponent as CovidIcon } from '../assets/covid.svg';
import { ReactComponent as SolarSystemIcon } from '../assets/solar-system.svg';
import SearchInput from '../components/SearchInput';
import VirtualList from '../components/VirtualList';
import { colors } from '../utils/theme';
import EnrolledStudentCell, { EnrolledStudentCellHeight } from './EnrolledStudentCell';
import { useStudentList } from './hooks';
import { StudentCovidDataFilter } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: colors.grey1,
  },
  search: {
    flex: 1,
    maxWidth: 480,
    marginRight: theme.spacing(3),
  },
  stats: {
    display: 'grid',
    color: materialColors.red[500],
    grid: '16px 24px / auto 1fr',
    gridTemplateAreas: '"stats-icon stats-title" "stats-icon stats-value"',
    marginRight: theme.spacing(3),
    fontWeight: 'bold',
    lineHeight: 1.33,

    '&.red': {
      color: colors.red,
    },

    '&.orange': {
      color: colors.orange,
    },
  },
  statsIcon: {
    gridArea: 'stats-icon',
    width: 36,
    height: 36,
    marginRight: theme.spacing(1.5),
  },
  statsTitle: {
    gridArea: 'stats-title',
    fontSize: '0.75rem',
  },
  statsValue: {
    color: colors.grey1,
    gridArea: 'stats-value',
    fontSize: '1.125rem',
  },
}));

const EnrolledStudents = () => {
  const styles = useStyles();

  const { loadMoreItems, searchTerm, setSearchTerm, studentData, studentSummary } = useStudentList(
    StudentCovidDataFilter.ENROLLED
  );

  if (!studentSummary.numEnrolled) {
    return (
      <div className={styles.root}>
        <h2 className={styles.title}>Enrolled students</h2>
        <p>No enrolled students have been traced yet</p>
      </div>
    );
  }

  return (
    <VirtualList
      className={styles.root}
      data={studentData}
      header={
        <>
          <h2 className={styles.title}>Enrolled students</h2>
          <div className={styles.header}>
            <SearchInput
              className={styles.search}
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search by name or email address"
            />
            <div className={styles.stats}>
              <CovidIcon className={styles.statsIcon} />
              <div className={styles.statsTitle}>STUDENTS OF CONCERN</div>
              <div className={styles.statsValue}>{studentSummary.numConcern}</div>
            </div>
            <div className={styles.stats}>
              <SolarSystemIcon className={styles.statsIcon} />
              <div className={styles.statsTitle}>EXPOSED STUDENTS</div>
              <div className={styles.statsValue}>{studentSummary.numExposed}</div>
            </div>
          </div>
        </>
      }
      isItemLoaded={(index) => Boolean(studentData[index])}
      itemCount={studentData.length}
      itemKey={(index, data) => data[index]?.userId || `enrolled-${index}`}
      loadMoreItems={loadMoreItems}
      rowHeight={EnrolledStudentCellHeight}
    >
      {EnrolledStudentCell}
    </VirtualList>
  );
};

export default EnrolledStudents;
