import { useParams } from 'react-router';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import UserCell, { UserCellLoading } from '../components/UserCell';
import VirtualList from '../components/VirtualList';
import { colors } from '../utils/theme';
import AffectedCoursesCell, { AffectedCoursesCellHeight } from './AffectedCoursesCell';
import { useCourseStudentList } from './hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  user: {
    minWidth: 300,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: colors.grey1,
  },
  stats: {
    display: 'flex',
    minWidth: 198,
    height: 48,
    textAlign: 'right',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
  },
  statsTitle: {
    color: 'rgba(51, 51, 51, 0.5)',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    height: 16,
  },
  statsValue: {
    color: colors.grey1,
    fontSize: '1.125rem',
    lineHeight: 1.78,
    height: 32,
  },
  loaderStrip: theme.mixins.loaderStrip,
}));

interface RouteParams {
  courseId: string;
}

const AffectedCourses = () => {
  const styles = useStyles();
  const { courseId } = useParams<RouteParams>();

  const { loadMoreItems, studentData, affectedCourse } = useCourseStudentList(courseId);

  return (
    <VirtualList
      className={styles.root}
      data={studentData}
      header={
        <>
          <h2 className={styles.title}>
            {affectedCourse?.code || <div className={clsx(styles.loaderStrip, 'quarter', 'tall')} />}
          </h2>
          <div className={styles.header}>
            {affectedCourse?.admin ? (
              <UserCell
                className={styles.user}
                tag="Course Admin"
                avatarURL={affectedCourse.admin.avatar}
                name={affectedCourse.admin.name}
                email={affectedCourse.admin.emailId}
              />
            ) : (
              <UserCellLoading className={styles.user} />
            )}
            <div className={styles.stats}>
              <div className={styles.statsTitle}>
                {affectedCourse ? 'Lectures conducted' : <div className={styles.loaderStrip} />}
              </div>
              <div className={styles.statsValue}>
                {affectedCourse?.lecturesConducted || (
                  <div className={clsx(styles.loaderStrip, 'half', 'tall')} />
                )}
              </div>
            </div>
          </div>
        </>
      }
      isItemLoaded={(index) => Boolean(studentData[index])}
      itemCount={studentData.length}
      itemKey={(index, data) => data[index]?.userId || `enrolled-${index}`}
      loadMoreItems={loadMoreItems}
      rowHeight={AffectedCoursesCellHeight}
    >
      {AffectedCoursesCell}
    </VirtualList>
  );
};

export default AffectedCourses;
