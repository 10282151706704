import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import registerInterceptors from './utils/request-interceptors';

if (process.env.REACT_APP_USE_MOCKS) {
  let mockAdapter = require('./utils/mocks').registerMocks();

  if ((module as any).hot) {
    (module as any).hot.accept('./utils/mocks', () => {
      mockAdapter.reset();
      mockAdapter = require('./utils/mocks').registerMocks();
    });
  }
}

registerInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
