import { ChangeEventHandler, CSSProperties } from 'react';

import { makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import clsx from 'clsx';

import { colors } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  searchIcon: {
    opacity: 0.5,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: theme.spacing(1.5),
  },
  input: {
    width: '100%',
    height: theme.spacing(5.5),
    color: 'inherit',
    paddingLeft: theme.spacing(5),
    lineHeight: 1.5,
    fontSize: '1rem',
    borderRadius: 1000,
    backgroundColor: '#fff',
    outline: 'none',
    border: `2px solid ${theme.palette.background.default}`,
    boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.25)',

    '&:focus': {
      border: `2px solid ${colors.blue1}`,
    },
  },
}));

interface Props {
  style?: CSSProperties;
  className?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => unknown;
}

const SearchInput = ({ className, value, placeholder, style, onChange }: Props) => {
  const styles = useStyles();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => onChange(e.target.value);

  return (
    <div className={clsx(className, styles.root)} style={style}>
      <SearchIcon className={styles.searchIcon} />
      <input
        className={styles.input}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;
