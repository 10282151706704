import {
  colors as materialColors,
  unstable_createMuiStrictModeTheme as createTheme,
} from '@material-ui/core';

export const colors = {
  blue1: '#2F80ED',
  lightBlue1: 'rgba(47, 128, 237, 0.6)',
  blue2: '#2D9CDB',
  purple1: '#9B51E0',
  lightPurple1: 'rgba(155, 81, 224, 0.6)',
  purple2: '#7B14A2',
  red: '#EB5757',
  grey1: '#333333',
  grey2: '#4F4F4F',
  grey3: '#828282',
  grey4: '#BDBDBD',
  green1: '#219653',
  orange: '#F2994A',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2F80ED',
    },
    secondary: {
      main: materialColors.deepOrange[500],
    },
  },
  mixins: {
    loaderStrip: {
      backgroundColor: '#ced4da',
      borderRadius: 4,
      width: '80%',
      height: 12,
      display: 'inline-block',
      verticalAlign: 'middle',

      '&.right': {
        marginLeft: 'auto',
      },

      '&.tall': {
        height: 16,
      },

      '&.half': {
        width: '50%',
      },

      '&.quarter': {
        width: '25%',
      },
    },
    loaderAvatar: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      backgroundColor: '#ced4da',
    },
    ellipsize: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});
