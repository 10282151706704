import { useMemo } from 'react';

import { Avatar, colors as materialColors, makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { colors } from '../utils/theme';
import Tag from './Tag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto minmax(20px, 100%)',
    gridTemplateRows: '1fr 1fr',
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  avatar: {
    gridRow: '1/3',
    gridColumn: 1,
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
    backgroundColor: materialColors.deepOrange[500],
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: colors.grey1,
    gridRow: 1,
    gridColumn: 2,
  },
  tag: {
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    ...theme.mixins.ellipsize,
    color: 'rgba(51, 51, 51, 0.7)',
    gridRow: 2,
    gridColumn: 2,
  },
  name: theme.mixins.ellipsize,
  loaderStrip: theme.mixins.loaderStrip,
  loaderAvatar: theme.mixins.loaderAvatar,
}));

interface LoaderProps {
  className?: string;
}

export const UserCellLoading = ({ className }: LoaderProps) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.avatar, styles.loaderAvatar)} />
      <div className={styles.title}>
        <div className={clsx(styles.loaderStrip, 'tall', 'half')} />
      </div>
      <div className={styles.subtitle}>
        <div className={styles.loaderStrip} />
      </div>
    </div>
  );
};

interface Props {
  avatarURL: string;
  className?: string;
  email: string;
  name: string;
  tag?: string;
}

const UserCell = ({ avatarURL, className, email, name, tag }: Props) => {
  const styles = useStyles();

  const nameInitials = useMemo(
    () =>
      name
        .split(' ')
        .slice(0, 2)
        .map((n) => n[0].toUpperCase())
        .join(''),
    [name]
  );

  return (
    <div className={clsx(styles.root, className)}>
      <Avatar src={avatarURL} className={styles.avatar}>
        {nameInitials}
      </Avatar>
      <div className={styles.title}>
        <div className={styles.name} title={name}>
          {name}
        </div>
        {tag && <Tag className={styles.tag}>{tag}</Tag>}
      </div>
      <div className={styles.subtitle} title={email}>
        {email}
      </div>
    </div>
  );
};

export default UserCell;
