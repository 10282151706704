import { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Link, Paper, TextField } from '@material-ui/core';

import LoaderButton from '../components/LoaderButton';
import { pluralize } from '../utils/helpers';
import { resendVerificationCode, verifyEmail } from './api';
import { setScreen } from './reducer';
import { useAuthStyles } from './styles';

const ForgotPassword = () => {
  const styles = useAuthStyles();
  const dispatch = useDispatch();

  const [secondsLeft, setSecondsLeft] = useState(0);
  const [canResendVerificationCode, setCanResendVerificationCode] = useState(true);

  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleResendVerificationCode = async () => {
    let seconds = 60;

    setSecondsLeft(seconds);
    setCanResendVerificationCode(false);

    const timer = setInterval(() => {
      if (seconds <= 1) {
        clearInterval(timer);
        setCanResendVerificationCode(true);
        return;
      }

      setSecondsLeft(--seconds);
    }, 1000);

    try {
      await resendVerificationCode();
    } catch (error) {
      setError('Some error occured! Please try after sometime.');
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const { message, errorMessage, next } = await verifyEmail({
        verificationCode,
      });

      if (message === 'error') {
        setError(errorMessage);
        setIsSubmitting(false);
        return;
      }

      dispatch(setScreen(next));
    } catch (error) {
      setIsSubmitting(false);
      setError('Some error occured! Please try after sometime.');
    }
  };

  return (
    <Paper elevation={3} component="form" className={styles.form} onSubmit={handleSubmit}>
      <h2 className={styles.formTitle}>Enter verification code</h2>
      <p className={styles.formDescription}>
        We have emailed a verification code to your registered email address
      </p>
      <TextField
        required
        fullWidth
        error={Boolean(error)}
        helperText={error}
        type="text"
        variant="standard"
        value={verificationCode}
        onChange={handleChange}
        autoCapitalize="off"
        placeholder="Enter the verification code here"
        inputProps={{ className: styles.textCenter }}
        FormHelperTextProps={{ className: styles.textCenter }}
      />
      <div className={styles.actions}>
        {canResendVerificationCode ? (
          <Link className={styles.link} onClick={handleResendVerificationCode}>
            Resend verification code
          </Link>
        ) : (
          <div>
            {pluralize(secondsLeft, `Resend in ${secondsLeft} second`, `Resend in ${secondsLeft} seconds`)}
          </div>
        )}
        <LoaderButton isLoading={isSubmitting} color="primary" variant="contained" type="submit">
          Next
        </LoaderButton>
      </div>
    </Paper>
  );
};

export default ForgotPassword;
