import { Button, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

const LOADER_SIZE = 24;

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  loader: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -(LOADER_SIZE / 2),
    marginLeft: -(LOADER_SIZE / 2),
  },
});

interface Props extends ButtonProps {
  isLoading: boolean;
}

const LoaderButton = ({ isLoading, ...buttonProps }: Props) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Button disabled={isLoading} {...buttonProps} />
      {isLoading && <CircularProgress size={LOADER_SIZE} className={styles.loader} />}
    </div>
  );
};

export default LoaderButton;
