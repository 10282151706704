import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

interface Props {
  className?: string;
  children: string;
}

const EmptyMessage = ({ className, children }: Props) => {
  const styles = useStyles();
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

export default EmptyMessage;
