import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ListChildComponentProps } from 'react-window';

import { colors as materialColors, makeStyles, Tooltip } from '@material-ui/core';

import clsx from 'clsx';
import { format } from 'date-fns';

import { AppDispatch } from '../app/store';
import UserCell, { UserCellLoading } from '../components/UserCell';
import { showStudentDialog } from '../home/reducer';
import { colors } from '../utils/theme';
import { StudentCovidData } from './types';

export const ConcernedStudentCellHeight = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'grid',
    padding: theme.spacing(1, 0),
    height: ConcernedStudentCellHeight,
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gridTemplateAreas: `"user courses test-date students-exposed"`,
    borderBottom: '1px solid rgba(186, 186, 186, 0.5)',
    textAlign: 'right',

    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  user: {
    alignSelf: 'center',
    gridRow: '1/3',
    gridColumn: 1,
    marginLeft: theme.spacing(2),
    textAlign: 'left',
  },
  cellHeader: {
    color: 'rgba(51, 51, 51, 0.5)',
    height: '1rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  cellValue: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: colors.grey1,
  },
  courses: {
    gridArea: 'courses',
  },
  testDate: {
    gridArea: 'test-date',
  },
  studentsExposed: {
    gridArea: 'students-exposed',
  },
  loaderStrip: theme.mixins.loaderStrip,
  loaderAvatar: theme.mixins.loaderAvatar,
}));

const exposedStudentsColorMap: [number, string][] = [
  [120, materialColors.red[500]],
  [90, materialColors.red[400]],
  [60, materialColors.orange[500]],
  [30, materialColors.yellow['A700']],
  [Number.NEGATIVE_INFINITY, materialColors.grey[500]],
];

const ConcernedStudentCell = ({
  index,
  isScrolling,
  style,
  data,
}: ListChildComponentProps<SparseArray<StudentCovidData>>) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const student = data[index];

  const testDateFormatted = useMemo(() => {
    if (!student?.testDate) return 'N/A';
    const date = new Date(student.testDate * 1000);
    return format(date, 'do MMMM, ‘yy');
  }, [student?.testDate]);

  const exposedCellColor = useMemo(() => {
    if (!student) return undefined;

    for (const [value, color] of exposedStudentsColorMap) {
      if (student.studentsExposed > value) return color;
    }
  }, [student]);

  const handleClick = useCallback(() => {
    if (!student?.userId) return;
    dispatch(showStudentDialog({ studentId: student.userId }));
  }, [dispatch, student?.userId]);

  if (!student || isScrolling) {
    return (
      <div className={styles.root} style={style}>
        <UserCellLoading className={styles.user} />
        <div className={styles.courses}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
        <div className={styles.testDate}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
        <div className={styles.studentsExposed}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
      </div>
    );
  }

  const { avatar, name, emailId, coursesEnrolledIn, studentsExposed } = student;

  return (
    <div className={styles.root} style={style} onClick={handleClick}>
      <UserCell className={styles.user} avatarURL={avatar} name={name} email={emailId} />
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title="Number of courses attended this student is enrolled in"
      >
        <div className={styles.courses}>
          <div className={styles.cellHeader}>Courses</div>
          <div className={styles.cellValue}>{coursesEnrolledIn}</div>
        </div>
      </Tooltip>
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title="Date on which the student tested Positive, as reported via the Contact Trace dashboard"
      >
        <div className={styles.testDate}>
          <div className={styles.cellHeader}>Reported on</div>
          <div className={styles.cellValue}>{testDateFormatted}</div>
        </div>
      </Tooltip>
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title="Number of students with whom the student of concern has shared a classroom since the date of concern"
      >
        <div className={styles.studentsExposed}>
          <div className={styles.cellHeader}>Students exposed</div>
          <div className={styles.cellValue} style={{ color: exposedCellColor }}>
            {studentsExposed}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ConcernedStudentCell;
