import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import UserCell, { UserCellLoading } from '../components/UserCell';
import { colors } from '../utils/theme';
import { StudentEnrolledCourse } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateAreas: '"title lectures-attended" "user concerned-students"',
    gridTemplateColumns: 'minmax(200px, 100%) 160px',
    border: '1px solid #E0E0E0',
    borderRadius: 8,
    padding: theme.spacing(1, 2, 1, 1),
    gap: theme.spacing(2),
  },
  label: {
    height: 16,
    lineHeight: 1.33,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'rgba(51, 51, 51, 0.5)',
  },
  value: {
    ...theme.mixins.ellipsize,
    height: 32,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: colors.grey1,
  },
  title: {
    gridArea: 'title',
  },
  lecturesAttended: {
    gridArea: 'lectures-attended',
    textAlign: 'right',
  },
  user: {
    gridArea: 'user',
  },
  concernedStudents: {
    gridArea: 'concerned-students',
    textAlign: 'right',
  },
  loaderStrip: theme.mixins.loaderStrip,
}));

interface StudentCourseLoadingProps {
  className?: string;
}

export const StudentCourseLoading = ({ className }: StudentCourseLoadingProps) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.title}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'quarter')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.loaderStrip, 'tall')} />
        </div>
      </div>
      <div className={styles.lecturesAttended}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'right')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.loaderStrip, 'tall', 'quarter', 'right')} />
        </div>
      </div>
      <div className={styles.concernedStudents}>
        <div className={styles.label}>
          <div className={clsx(styles.loaderStrip, 'right')} />
        </div>
        <div className={styles.value}>
          <div className={clsx(styles.loaderStrip, 'tall', 'quarter', 'right')} />
        </div>
      </div>
      <UserCellLoading className={styles.user} />
    </div>
  );
};

interface Props extends StudentEnrolledCourse {
  className?: string;
}

const StudentCourse = ({ admin, className, code, lecturesAttended, numConcern, title }: Props) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.title}>
        <div className={styles.label}>{code}</div>
        <div className={styles.value}>{title}</div>
      </div>
      <div className={styles.lecturesAttended}>
        <div className={styles.label}>Lectures attended</div>
        <div className={styles.value}>{lecturesAttended}</div>
      </div>
      <div className={styles.concernedStudents}>
        <div className={styles.label}>Students of concern</div>
        <div className={styles.value}>{numConcern}</div>
      </div>
      <UserCell
        className={styles.user}
        tag="Course Admin"
        name={admin.name}
        email={admin.emailId}
        avatarURL={admin.avatar}
      />
    </div>
  );
};

export default StudentCourse;
