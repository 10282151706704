import { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import clsx from 'clsx';

import { colors } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'space-between',
  },
  header: {
    color: colors.blue2,
    padding: theme.spacing(1, 2),
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 1.2,
    background: '#fff',
    borderRadius: 8,

    '&.purple': {
      color: colors.purple1,
    },
  },
  item: {
    color: '#fff',
    padding: theme.spacing(0.75, 2),
    fontSize: '1rem',
    lineHeight: 1.5,
    backgroundColor: colors.blue1,

    '&.active': {
      backgroundColor: colors.lightBlue1,
      paddingRight: 0,
    },

    '&.purple': {
      backgroundColor: colors.purple1,
    },

    '&.active.purple': {
      backgroundColor: colors.lightPurple1,
    },
  },
  rightWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  disclosure: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    borderTop: `1px solid #2D9CDB`,

    '&.purple': {
      borderColor: colors.purple2,
    },

    '&:last-child': {
      display: 'none',
    },
  },
}));

interface Props extends Omit<LinkProps, 'to'> {
  to?: string;
  isHeader?: boolean;
  label: string;
  variant: 'blue' | 'purple';
  rightLabel?: string | number;
}

const NavPanelItem = forwardRef<HTMLAnchorElement | HTMLDivElement, Props>(
  ({ isHeader, label, rightLabel, variant, to, ...linkProps }, ref) => {
    const styles = useStyles();

    const isActive = !!useRouteMatch(to || '');

    if (isHeader || !to) {
      return (
        <>
          <div
            className={clsx(styles.root, variant, isHeader ? styles.header : styles.item)}
            ref={ref as ForwardedRef<HTMLDivElement>}
          >
            {label}
            <div className={styles.rightWrapper}>{rightLabel}</div>
          </div>
          {!isHeader && <div className={clsx(styles.divider, variant)} />}
        </>
      );
    }

    return (
      <>
        <Link
          className={clsx(styles.root, variant, isHeader ? styles.header : styles.item, {
            active: isActive,
          })}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          to={to}
          {...linkProps}
        >
          {label}
          <div className={styles.rightWrapper}>
            {rightLabel}
            {isActive && <ChevronRight className={styles.disclosure} />}
          </div>
        </Link>
        {!isActive && <div className={clsx(styles.divider, variant)} />}
      </>
    );
  }
);

export default NavPanelItem;
