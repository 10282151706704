import { MouseEvent, MouseEventHandler } from 'react';

import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { colors } from '../utils/theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    borderRadius: 4,
    backgroundColor: colors.blue1,
    border: '2px solid transparent',
  },
  tab: {
    flex: 1,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: 1.5,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    color: colors.blue1,
    height: 32,

    '&.active': {
      backgroundColor: 'transparent',
      color: '#fff',
    },

    '&:first-child': {
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    },

    '&:last-child': {
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
  },
});

interface Tab<T extends string> {
  id: T;
  title: string;
  onClick?: MouseEventHandler;
}

interface Props<T extends string> {
  className?: string;
  activeTabId: T;
  tabs: Tab<T>[];
  onTabSelect: (selectedTabId: T) => unknown;
}

const Tabs = <T extends string>({ className, activeTabId, tabs, onTabSelect }: Props<T>) => {
  const styles = useStyles();

  const handleTabClick = (tab: Tab<T>) => (e: MouseEvent) => {
    if (tab.onClick) {
      tab.onClick(e);
    }
    onTabSelect(tab.id);
  };

  return (
    <div className={clsx(styles.root, className)}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={clsx(styles.tab, {
            active: activeTabId === tab.id,
          })}
          onClick={handleTabClick(tab)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
