import { colors as materialColors, makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { ReactComponent as CovidIcon } from '../assets/covid.svg';
import { ReactComponent as SolarSystemIcon } from '../assets/solar-system.svg';
import { colors } from '../utils/theme';
import { CovidStatus, Proximity } from '../utils/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '&.very-close': {
      color: materialColors.red[500],
    },

    '&.close': {
      color: materialColors.red[400],
    },

    '&.moderate': {
      color: materialColors.orange[500],
    },

    '&.far': {
      color: materialColors.yellow['A700'],
    },

    '&.very-far': {
      color: materialColors.grey[500],
    },

    '&.unknown': {
      color: colors.grey1,
    },
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(0.75),
  },
}));

interface Props {
  className?: string;
  classes?: {
    icon?: string;
  };
  proximity: Proximity;
  covidStatus: CovidStatus;
}

const Exposure = ({ className, classes, proximity, covidStatus }: Props) => {
  const styles = useStyles();
  return (
    <div
      className={clsx(className, styles.root, covidStatus === CovidStatus.UNKNOWN ? 'unknown' : proximity)}
    >
      {covidStatus === CovidStatus.POSITIVE ? (
        <>
          <CovidIcon className={clsx(styles.icon, classes?.icon)} />
          <div>POSITIVE</div>
        </>
      ) : covidStatus === CovidStatus.EXPOSED ? (
        <>
          <SolarSystemIcon className={clsx(styles.icon, classes?.icon)} />
          <div>EXPOSED</div>
        </>
      ) : (
        <div>--</div>
      )}
    </div>
  );
};

export default Exposure;
