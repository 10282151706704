import MockAdapter from 'axios-mock-adapter/types';
import { lib } from 'crypto-js';

import { CovidStatus, Proximity } from '../utils/types';
import { GetStudentsForCourseResponse } from './api';
import { StudentCovidDataForCourse } from './types';

function randomId() {
  return lib.WordArray.random(2).toString();
}

function getSeedStudentData(): StudentCovidDataForCourse[] {
  return [
    {
      userId: randomId(),
      avatar: '',
      name: 'Alan Cooper',
      emailId: 'alan.cooper@uic.edu',
      proximity: Proximity.VERY_CLOSE,
      status: CovidStatus.EXPOSED,
      lecturesAttended: 5,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'James Gouse',
      emailId: 'jamesg@uic.edu',
      proximity: Proximity.CLOSE,
      status: CovidStatus.EXPOSED,
      lecturesAttended: 4,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Alexis Sears',
      emailId: 'asears1@uic.edu',
      proximity: Proximity.MODERATE,
      status: CovidStatus.EXPOSED,
      lecturesAttended: 4,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Anna White',
      emailId: 'aw32@uic.edu',
      proximity: Proximity.FAR,
      status: CovidStatus.EXPOSED,
      lecturesAttended: 3,
    },
    {
      userId: randomId(),
      avatar: '',
      name: 'Skylar Dias',
      emailId: 'skylard@uic.edu',
      proximity: Proximity.VERY_FAR,
      status: CovidStatus.EXPOSED,
      lecturesAttended: 5,
    },
  ];
}

function getStudentData(count: number) {
  const data = getSeedStudentData();
  const result: StudentCovidDataForCourse[] = [];

  const repeat = Math.floor(count / data.length);
  const remainder = count % data.length;

  for (let i = 0; i < repeat; i++) {
    result.push(...getSeedStudentData());
  }

  result.push(...data.slice(0, remainder));

  return result;
}

export default function registerCoursesMocks(mockAdapter: MockAdapter) {
  mockAdapter.onGet(/^\/students\/course\/\w+$/).reply<GetStudentsForCourseResponse>(async (config) => {
    const { limit } = config.params;
    return [
      200,
      {
        message: 'success',
        course: {
          admin: {
            name: 'Jessica Lambert',
            emailId: 'jwlambert@uic.edu',
            avatar: '',
            userId: '',
          },
          code: 'MATH-101',
          endDate: 1629743400,
          startDate: 1629743400,
          lecturesConducted: 5,
          title: 'Introduction to Maths',
        },
        students: getStudentData(limit),
      },
    ];
  });
}
