import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getCovidSummary,
  getExposedStudents,
  getStudentCourses,
  getStudentCovidTestRecord,
  updateCovidTestRecord as updateCovidTestRecordAPI,
  UpdateCovidTestRecordData,
} from './api';
import {
  CovidSummary,
  CovidTestRecord,
  ExposedStudent,
  StudentEnrolledCourse,
  StudentProfile,
} from './types';

export const HOME = 'home';

interface HomeState extends CovidSummary {
  isLoading: boolean;
  studentDialog: {
    isOpen: boolean;
    studentId: string;
    studentProfile: StudentProfile | null;
    enrolledCourses: StudentEnrolledCourse[] | null;
    exposedStudents: ExposedStudent[] | null;
    testRecord: CovidTestRecord | null;
  };
}

const initialState: HomeState = {
  isLoading: true,
  students: {
    numConcern: 0,
    numEnrolled: 0,
    numExposed: 0,
  },
  courses: {
    numAffected: 0,
    numLectures: 0,
    numRecord: 0,
    affected: [],
  },
  studentDialog: {
    isOpen: false,
    studentId: '',
    studentProfile: null,
    enrolledCourses: null,
    exposedStudents: null,
    testRecord: null,
  },
};

type ShowStudentProfileDialogAction = PayloadAction<StudentProfile | null>;

const homeSlice = createSlice({
  name: HOME,

  initialState,

  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCovidSummary(state, action: PayloadAction<CovidSummary>) {
      state.students = action.payload.students;
      state.courses = action.payload.courses;
    },
    showStudentDialog(state, action: PayloadAction<{ studentId: string }>) {
      state.studentDialog = {
        isOpen: true,
        studentId: action.payload.studentId,
        studentProfile: null,
        enrolledCourses: null,
        exposedStudents: null,
        testRecord: null,
      };
    },
    hideStudentDialog(state) {
      state.studentDialog.isOpen = false;
    },
    setStudentProfile(state, action: ShowStudentProfileDialogAction) {
      state.studentDialog.studentProfile = action.payload;
    },
    setEnrolledCourses(state, action: PayloadAction<StudentEnrolledCourse[]>) {
      state.studentDialog.enrolledCourses = action.payload;
    },
    setExposedStudents(state, action: PayloadAction<ExposedStudent[]>) {
      state.studentDialog.exposedStudents = action.payload;
    },
    setStudentCovidTestRecord(state, action: PayloadAction<CovidTestRecord>) {
      state.studentDialog.testRecord = action.payload;
    },
  },
});

export const {
  hideStudentDialog,
  setLoading: setHomeLoading,
  setCovidSummary,
  setEnrolledCourses,
  setExposedStudents,
  setStudentCovidTestRecord,
  setStudentProfile,
  showStudentDialog,
} = homeSlice.actions;

export const fetchCovidSummary = createAsyncThunk(
  `${HOME}/fetchCovidSummary`,
  async (_args: void, { dispatch }) => {
    dispatch(setHomeLoading(true));

    const { students, courses } = await getCovidSummary();

    dispatch(setHomeLoading(false));
    dispatch(setCovidSummary({ students, courses }));

    return { students, courses };
  }
);

export const fetchStudentCourses = createAsyncThunk(
  `${HOME}/fetchStudentCourses`,
  async (studentId: string, { dispatch }) => {
    const response = await getStudentCourses(studentId);
    dispatch(setStudentProfile(response.student));
    dispatch(setEnrolledCourses(response.courses));
  }
);

export const fetchExposedStudents = createAsyncThunk(
  `${HOME}/fetchExposedStudents`,
  async (studentId: string, { dispatch }) => {
    const response = await getExposedStudents(studentId);
    dispatch(setStudentProfile(response.student));
    dispatch(setExposedStudents(response.exposure));
  }
);

export const fetchStudentCovidTestRecord = createAsyncThunk(
  `${HOME}/fetchStudentCovidTestRecord`,
  async (studentId: string, { dispatch }) => {
    const response = await getStudentCovidTestRecord(studentId);
    dispatch(setStudentProfile(response.student));
    dispatch(setStudentCovidTestRecord(response.testRecord));
  }
);

export const updateCovidTestRecord = createAsyncThunk(
  `${HOME}/updateCovidTestRecord`,
  async (data: UpdateCovidTestRecordData, { dispatch }) => {
    const response = await updateCovidTestRecordAPI(data);
    dispatch(setStudentProfile(response.student));
    dispatch(setStudentCovidTestRecord(response.testRecord));
  }
);

const homeReducer = homeSlice.reducer;

export default homeReducer;
