import { makeStyles } from '@material-ui/core';

import { colors } from '../utils/theme';

export const useAuthStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflow: 'auto',
    color: colors.grey1,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#E5E5E5',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 480,
  },
  logo: {
    height: 60,
    width: 'auto',
    marginBottom: theme.spacing(3.25),
  },
  header: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: 0,
    marginBottom: theme.spacing(3.25),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    minHeight: 360,
    padding: theme.spacing(5, 7.5, 6.5),
    marginBottom: theme.spacing(3.25),
  },
  formTitle: {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 0,
  },
  formDescription: {
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    minHeight: 40,
    marginBottom: theme.spacing(2.5),
  },
  hidden: {
    display: 'none',
  },
  textCenter: {
    textAlign: 'center',
  },
  actions: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    cursor: 'pointer',
  },
  poweredBy: {
    textAlign: 'center',
  },
}));
