import { ChangeEventHandler, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  Input,
  InputAdornment,
  InputBaseComponentProps,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface Props {
  autoComplete: 'new-password' | 'current-password';
  error?: string;
  formHelperTextProps?: FormHelperTextProps;
  fullWidth?: boolean;
  inputProps?: InputBaseComponentProps;
  onChange: ChangeEventHandler;
  placeholder?: string;
  value: string;
}

const PasswordInput = ({
  autoComplete,
  error,
  formHelperTextProps,
  fullWidth,
  inputProps,
  onChange,
  placeholder,
  value,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((showPassword) => !showPassword);

  return (
    <FormControl fullWidth={fullWidth} error={Boolean(error)}>
      <Input
        required
        inputProps={inputProps}
        type={showPassword ? 'text' : 'password'}
        autoComplete={autoComplete}
        autoCapitalize="off"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword} onMouseDown={toggleShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText {...formHelperTextProps}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default PasswordInput;
