import './custom-scrollbar.css';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { parse } from 'query-string';

import Auth from '../auth';
import { fetchLTIAccessData, fetchUniversityData } from '../auth/reducer';
import { selectIsAuthenticated } from '../auth/selectors';
import Home from '../home';
import history from '../utils/history';
import { theme } from '../utils/theme';
import ErrorPage from './ErrorPage';
import { AppDispatch } from './store';

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [isUniverisityDataFetched, setIsUniverisityDataFetched] = useState(false);

  useEffect(() => {
    async function init() {
      await dispatch(fetchUniversityData());
      setIsUniverisityDataFetched(true);
    }
    init();
  }, [dispatch]);

  useEffect(() => {
    const { access_token } = parse(window.location.search);

    const accessCode = access_token instanceof Array ? access_token[0] : access_token;

    if (accessCode && isUniverisityDataFetched && !isAuthenticated) {
      dispatch(fetchLTIAccessData(accessCode));
    }
  }, [dispatch, isAuthenticated, isUniverisityDataFetched]);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <CssBaseline />
          <Switch>
            <Route path="/error">
              <ErrorPage />
            </Route>
            <Route path="/login">
              <Auth />
            </Route>
            <Route path={['/students', '/courses']}>
              <Home />
            </Route>
            <Route path="*">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
