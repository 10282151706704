import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import { AppDispatch } from '../app/store';
import { fetchStudentCourses } from './reducer';
import { selectEnrolledCourses } from './selectors';
import StudentCourse, { StudentCourseLoading } from './StudentCourse';

const useStyles = makeStyles((theme) => ({
  course: {
    marginBottom: theme.spacing(2.5),
  },
}));

interface Props {
  className?: string;
  studentId: string;
}

const EnrolledCourses = ({ className, studentId }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const enrolledCourses = useSelector(selectEnrolledCourses);

  useEffect(() => {
    if (!enrolledCourses) {
      dispatch(fetchStudentCourses(studentId));
    }
  }, [dispatch, enrolledCourses, studentId]);

  return (
    <div className={className}>
      {!enrolledCourses ? (
        <>
          <StudentCourseLoading className={styles.course} />
          <StudentCourseLoading className={styles.course} />
        </>
      ) : (
        enrolledCourses.map((course) => (
          <StudentCourse key={course.courseId} className={styles.course} {...course} />
        ))
      )}
    </div>
  );
};

export default EnrolledCourses;
