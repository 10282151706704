import { ReactNode, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogActions, IconButton, makeStyles, useScrollTrigger } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import clsx from 'clsx';

import { AppDispatch } from '../app/store';
import Exposure from '../components/Exposure';
import Tabs from '../components/Tabs';
import UserCell, { UserCellLoading } from '../components/UserCell';
import { colors } from '../utils/theme';
import CovidTestForm from './CovidTestForm';
import EnrolledCourses from './EnrolledCourses';
import ExposedStudents from './ExposedStudents';
import { hideStudentDialog } from './reducer';
import { selectStudentDialogState } from './selectors';

const STATUS_WIDTH = 120;
const COURSE_ENROLLED_IN = 150;

const ANIMATION_DURATION = 200;

const HEADER_HEIGHT = 64;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    overflow: 'hidden',
    height: 580,
    maxHeight: '80vh',
  },
  header: {
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    padding: theme.spacing(2, '48px', 2, 2),
  },
  close: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  user: {
    flex: 1,
  },
  stats: {
    color: '#000',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  statsValue: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
    color: colors.grey2,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1.25),
  },
  status: {
    opacity: 0,
    width: STATUS_WIDTH,
    transition: theme.transitions.create('opacity', {
      duration: ANIMATION_DURATION,
    }),

    '$header.sticky &': {
      opacity: 1,
    },

    '$body &': {
      opacity: 1,
    },
  },
  courseEnrolledIn: {
    opacity: 0,
    width: COURSE_ENROLLED_IN,
    transition: theme.transitions.create('opacity', {
      duration: ANIMATION_DURATION,
    }),

    '$header.sticky &': {
      opacity: 1,
    },

    '$body &': {
      opacity: 1,
    },
  },
  body: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(0, 2),
  },
  statsWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabs: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginBottom: theme.spacing(2),
  },
  loaderStrip: theme.mixins.loaderStrip,
}));

interface StatsCellProps {
  className?: string;
  isLoading?: boolean;
  title: string;
  children: ReactNode | ReactNode[];
}

const StatsCell = ({ className, isLoading, title, children }: StatsCellProps) => {
  const styles = useStyles();

  if (isLoading) {
    return (
      <div className={clsx(styles.stats, className)}>
        <div className={styles.statsValue}>
          <div className={styles.loaderStrip} />
        </div>
        <div className={styles.statsValue}>
          <div className={clsx(styles.loaderStrip, 'half', 'tall')} />
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(styles.stats, className)}>
      <div>{title}</div>
      <div className={styles.statsValue}>{children}</div>
    </div>
  );
};

enum TableTabs {
  COURSES = 'courses',
  EXPOSURE_INSTANCES = 'exposure_instances',
  COVID_TEST_RECORD = 'covid_test_record',
}

const StudentProfileDialog = () => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const bodyRef = useRef<HTMLDivElement>(null);
  const actionContainerRef = useRef<HTMLDivElement>(null);

  const [activeTab, setActiveTab] = useState(TableTabs.COURSES);

  const { isOpen, studentId, studentProfile: student } = useSelector(selectStudentDialogState);

  const isScrolled = useScrollTrigger({
    target: bodyRef.current || undefined,
    threshold: HEADER_HEIGHT,
    disableHysteresis: true,
  });

  const handleClose = () => {
    dispatch(hideStudentDialog());
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <div className={styles.root}>
        <div className={clsx(styles.header, { sticky: isScrolled })}>
          {student ? (
            <UserCell
              className={styles.user}
              name={student.name}
              email={student.emailId}
              avatarURL={student.avatar}
            />
          ) : (
            <UserCellLoading className={styles.user} />
          )}
          <StatsCell className={styles.status} isLoading={!student} title="Status">
            {student && (
              <Exposure
                classes={{ icon: styles.icon }}
                covidStatus={student.status}
                proximity={student.proximity}
              />
            )}
          </StatsCell>
          <StatsCell className={styles.courseEnrolledIn} isLoading={!student} title="Courses enrolled in">
            {student?.coursesEnrolledIn}
          </StatsCell>
          <IconButton className={styles.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={clsx(styles.body, 'custom-scrollbar')} ref={bodyRef}>
          <div className={styles.statsWrapper}>
            <StatsCell className={styles.status} isLoading={!student} title="Status">
              {student && (
                <Exposure
                  classes={{ icon: styles.icon }}
                  covidStatus={student.status}
                  proximity={student.proximity}
                />
              )}
            </StatsCell>
            <StatsCell className={styles.courseEnrolledIn} isLoading={!student} title="Courses enrolled in">
              {student?.coursesEnrolledIn}
            </StatsCell>
          </div>
          <Tabs
            className={styles.tabs}
            activeTabId={activeTab}
            onTabSelect={setActiveTab}
            tabs={[
              {
                id: TableTabs.COURSES,
                title: 'Courses',
              },
              {
                id: TableTabs.EXPOSURE_INSTANCES,
                title: 'Exposure Instances',
              },
              {
                id: TableTabs.COVID_TEST_RECORD,
                title: 'COVID Test Record',
              },
            ]}
          />
          {TableTabs.COURSES === activeTab && <EnrolledCourses studentId={studentId} />}
          {TableTabs.EXPOSURE_INSTANCES === activeTab && (
            <ExposedStudents studentId={studentId} actionContainerRef={actionContainerRef} />
          )}
          {TableTabs.COVID_TEST_RECORD === activeTab && (
            <CovidTestForm studentId={studentId} actionContainerRef={actionContainerRef} />
          )}
        </div>
      </div>
      <DialogActions ref={actionContainerRef}>
        {/* corresponding tab content will be injected through react-portals */}
      </DialogActions>
    </Dialog>
  );
};

export default StudentProfileDialog;
