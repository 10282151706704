import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import AcadlyLogo from '../assets/acadly-logo.svg';
import PoweredBy from '../components/PoweredBy';
import ForgotPassword from './ForgotPassword';
import GetEmail from './GetEmail';
import GetPassword from './GetPassword';
import ResetPassword from './ResetPassword';
import { selectAuthScreen, selectIsAuthenticated, selectUniversity } from './selectors';
import SetPassword from './SetPassword';
import { useAuthStyles } from './styles';
import { AuthScreen } from './types';
import VerifyEmail from './VerifyEmail';

const Auth = () => {
  const styles = useAuthStyles();

  const screen = useSelector(selectAuthScreen);
  const university = useSelector(selectUniversity);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Redirect to="/students/enrolled" />;
  }

  return (
    <div className={styles.root}>
      <main className={styles.formWrapper}>
        <img className={styles.logo} src={university?.logo || AcadlyLogo} alt="App logo" />
        <h1 className={styles.header}>Contact Trace</h1>
        {screen === AuthScreen.EMAIL && <GetEmail />}
        {screen === AuthScreen.GET_PASSWORD && <GetPassword />}
        {screen === AuthScreen.VERIFY_EMAIL && <VerifyEmail />}
        {screen === AuthScreen.SET_PASSWORD && <SetPassword />}
        {screen === AuthScreen.RESET_PASSWORD && <ResetPassword />}
        {screen === AuthScreen.FORGOT_PASSWORD && <ForgotPassword />}
        <PoweredBy className={styles.poweredBy} />
      </main>
    </div>
  );
};

export default Auth;
