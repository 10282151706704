export function debounce<T, F extends (...args: any[]) => any>(
  this: T,
  func: F,
  timeout = 300
): (...args: Parameters<F>) => void {
  let timer: NodeJS.Timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
