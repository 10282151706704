import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { makeStyles } from '@material-ui/core';
import { Error as ErrorIcon } from '@material-ui/icons';

import { parse } from 'query-string';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.error.main,
  },
  icon: {
    height: 80,
    width: 'auto',
    marginRight: theme.spacing(3),
  },
  message: {
    fontSize: '1.5rem',
    margin: 0,
  },
}));

const ErrorPage = () => {
  const styles = useStyles();
  const { state, search } = useLocation<string | undefined>();

  const message = useMemo(() => {
    const result = parse(search);
    const message = result.message instanceof Array ? result.message[0] : result.message;
    return message || state || 'Some error occured!';
  }, [search, state]);

  return (
    <main className={styles.root}>
      <ErrorIcon className={styles.icon} />
      <p className={styles.message}>{message}</p>
    </main>
  );
};

export default ErrorPage;
