type PluralizeOptions =
  | string
  | {
      plural?: string;
      zero?: string;
    };

export function pluralize(count: number, singular: string, options: PluralizeOptions = {}) {
  if (typeof options === 'string') {
    options = { plural: options, zero: options };
  }

  const { plural = `${singular}s`, zero = `${singular}s` } = options;

  if (count === 0) return zero;
  if (count === 1) return singular;

  return plural;
}
