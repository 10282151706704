import { makeStyles } from '@material-ui/core';

import clsx from 'clsx';

import { colors } from '../utils/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    color: '#fff',
    backgroundColor: colors.blue1,
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.71,
    textTransform: 'uppercase',
    padding: theme.spacing(0, 0.75),
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  className?: string;
  children: string;
}

const Tag = ({ className, children }: Props) => {
  const styles = useStyles();
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

export default Tag;
