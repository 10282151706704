import { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Portal } from '@material-ui/core';

import { AppDispatch } from '../app/store';
import EmptyMessage from '../components/EmptyMessage';
import ExposedStudentCell, { ExposedStudentCellLoading } from './ExposedStudentCell';
import { fetchExposedStudents } from './reducer';
import { selectExposedStudents } from './selectors';

const useStyles = makeStyles((theme) => ({
  student: {
    marginBottom: theme.spacing(2.5),
  },
  warning: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.71,
    color: '#F2994A',
    width: '100%',
    marginLeft: theme.spacing(1),
  },
  empty: {
    minHeight: 280,
  },
}));

interface Props {
  className?: string;
  studentId: string;
  actionContainerRef: RefObject<HTMLDivElement>;
}

const ExposedStudents = ({ className, studentId, actionContainerRef }: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const exposedStudents = useSelector(selectExposedStudents);

  useEffect(() => {
    if (!exposedStudents) {
      dispatch(fetchExposedStudents(studentId));
    }
  }, [dispatch, exposedStudents, studentId]);

  return (
    <div className={className}>
      {!exposedStudents ? (
        <>
          <ExposedStudentCellLoading className={styles.student} />
          <ExposedStudentCellLoading className={styles.student} />
        </>
      ) : exposedStudents.length ? (
        exposedStudents.map((student) => (
          <ExposedStudentCell key={student.userId} className={styles.student} {...student} />
        ))
      ) : (
        <EmptyMessage className={styles.empty}>
          This student has had no reported exposure to a student of concern
        </EmptyMessage>
      )}
      <Portal container={actionContainerRef.current}>
        <div className={styles.warning}>
          If the student is positive, only the instances of exposure before the test date are shown
        </div>
      </Portal>
    </div>
  );
};

export default ExposedStudents;
