import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ListChildComponentProps } from 'react-window';

import { colors as materialColors, makeStyles, Tooltip } from '@material-ui/core';

import clsx from 'clsx';
import { format } from 'date-fns';

import { AppDispatch } from '../app/store';
import UserCell, { UserCellLoading } from '../components/UserCell';
import { showStudentDialog } from '../home/reducer';
import { colors } from '../utils/theme';
import { Proximity } from '../utils/types';
import { StudentCovidData } from './types';

export const ExposedStudentCellHeight = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'grid',
    padding: theme.spacing(1, 0),
    height: ExposedStudentCellHeight,
    gridTemplateColumns: '2fr 1fr 1fr 1fr',
    gridTemplateAreas: `"user affected-courses first-exposure proximity"`,
    borderBottom: '1px solid rgba(186, 186, 186, 0.5)',
    textAlign: 'right',

    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  user: {
    alignSelf: 'center',
    gridRow: '1/3',
    gridColumn: 1,
    marginLeft: theme.spacing(2),
    textAlign: 'left',
  },
  cellHeader: {
    color: 'rgba(51, 51, 51, 0.5)',
    height: '1rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  cellValue: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    lineHeight: 1.78,
    color: colors.grey1,
  },
  affectedCourses: {
    gridArea: 'affected-courses',
  },
  firstExposure: {
    gridArea: 'first-exposure',
  },
  proximity: {
    gridArea: 'proximity',
  },
  proximityValue: {
    '&.very-close': {
      color: materialColors.red[500],
    },

    '&.close': {
      color: materialColors.red[400],
    },

    '&.moderate': {
      color: materialColors.orange[500],
    },

    '&.far': {
      color: materialColors.yellow['A700'],
    },

    '&.very-far': {
      color: materialColors.grey[500],
    },

    '&.unknown': {
      color: colors.grey1,
    },
  },
  loaderStrip: theme.mixins.loaderStrip,
  loaderAvatar: theme.mixins.loaderAvatar,
}));

const proximityLabelMap: {
  [key in Proximity]: string;
} = {
  [Proximity.DEFAULT]: '--',
  [Proximity.UNKNOWN]: 'Unknown',
  [Proximity.VERY_FAR]: 'Very Far',
  [Proximity.FAR]: 'Far',
  [Proximity.MODERATE]: 'Moderate',
  [Proximity.CLOSE]: 'Close',
  [Proximity.VERY_CLOSE]: 'Very Close',
};

const proximityTooltipsMap: {
  [key in Proximity]: string;
} = {
  [Proximity.DEFAULT]: '',
  [Proximity.UNKNOWN]:
    'Student has attended at least one lecture with a student of concern since the date of first exposure but their proximity is unknown',
  [Proximity.VERY_FAR]:
    'Student has attended at least one lecture in very distant proximity of a student of concern since the date of first exposure',
  [Proximity.FAR]:
    'Student has attended at least one lecture in distant proximity of a student of concern since the date of first exposure',
  [Proximity.MODERATE]:
    'Student has attended at least one lecture in moderate proximity of a student of concern since the date of first exposure',
  [Proximity.CLOSE]:
    'Student has attended at least one lecture in close proximity of a student of concern since the date of first exposure',
  [Proximity.VERY_CLOSE]:
    'Student has attended at least one lecture in very close proximity of a student of concern since the date of first exposure',
};

const ExposedStudentCell = ({
  index,
  isScrolling,
  style,
  data,
}: ListChildComponentProps<SparseArray<StudentCovidData>>) => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const student = data[index];

  const handleClick = useCallback(() => {
    if (!student?.userId) return;
    dispatch(showStudentDialog({ studentId: student.userId }));
  }, [dispatch, student?.userId]);

  const firstExposureFormatted = useMemo(() => {
    if (!student?.firstExposure) return 'N/A';
    const date = new Date(student.firstExposure * 1000);
    return format(date, 'do MMMM, ‘yy');
  }, [student?.firstExposure]);

  if (!student || isScrolling) {
    return (
      <div className={styles.root} style={style}>
        <UserCellLoading className={styles.user} />
        <div className={styles.affectedCourses}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
        <div className={styles.firstExposure}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
        <div className={styles.proximity}>
          <div className={styles.cellHeader}>
            <div className={clsx(styles.loaderStrip, 'right')} />
          </div>
          <div className={styles.cellValue}>
            <div className={clsx(styles.loaderStrip, 'right', 'tall', 'half')} />
          </div>
        </div>
      </div>
    );
  }

  const { avatar, name, emailId, coursesEnrolledIn, proximity } = student;

  return (
    <div className={styles.root} style={style} onClick={handleClick}>
      <UserCell className={styles.user} avatarURL={avatar} name={name} email={emailId} />
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title="Number of courses attended by this student with at least 1 student of concern"
      >
        <div className={styles.affectedCourses}>
          <div className={styles.cellHeader}>Affected courses</div>
          <div className={styles.cellValue}>{coursesEnrolledIn}</div>
        </div>
      </Tooltip>
      <Tooltip
        enterDelay={1000}
        placement="top-end"
        title="Date of first possible exposure based on reporting data"
      >
        <div className={styles.firstExposure}>
          <div className={styles.cellHeader}>First exposure</div>
          <div className={styles.cellValue}>{firstExposureFormatted}</div>
        </div>
      </Tooltip>
      <Tooltip enterDelay={1000} placement="top-end" title={proximityTooltipsMap[proximity]}>
        <div className={styles.proximity}>
          <div className={styles.cellHeader}>Proximity</div>
          <div className={clsx(styles.cellValue, styles.proximityValue, proximity)}>
            {proximityLabelMap[proximity]}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ExposedStudentCell;
